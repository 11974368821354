import UpdateReturnItemSellerCommentsButton from '../../returns/components/UpdateReturnItemSellerCommentsButton';
import Stack from '@mui/material/Stack';
import ReturnItemButtons from '../../returns/components/ReturnItemButtons';
import { useQueryClient } from 'react-query';
import { ResolutionDossiersQueryKey } from '../../../../api/automator/resolutions/ResolutionDossiersQueryKey';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import AcceptOrRejectReturnItemButtons from '../../returns/components/AcceptOrRejectReturnItemButtons';
import HandlingResultChip from '../../returns/components/HandlingResultChip';
import CircularProgress from '@mui/material/CircularProgress';
import { ReturnItemTransporterChip } from '../../returns/components/ReturnItemTransporterChip';
import * as React from 'react';
import { useFetchReturnItem } from '../../../../api/automator/returns/useFetchReturnItem';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';

interface ReturnResolutionCaseActionsProps {
  resolutionCaseId: number;
  returnItemId: number;
  hasOpenResend: boolean;
}

const ReturnResolutionCaseActions = ({ returnItemId }: ReturnResolutionCaseActionsProps) => {
  const { data: returnItem, isLoading } = useFetchReturnItem(returnItemId);

  const queryClient = useQueryClient();

  if (isLoading) {
    return <CircularProgress />;
  }

  const processingResults = returnItem!.returnProcessingResults.sort((a, b) => a.id - b.id);
  const latestProcessingResult =
    processingResults.length > 0 ? processingResults[processingResults.length - 1] : null;

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
    >
      {/*<ReturnItemStatusChip returnItemStatus={returnItem.status} />*/}

      <Stack
        gap={1}
        direction="row"
      >
        {returnItem!.transporterInfos.map((info, index) => {
          return (
            info.trackAndTraceUrl && (
              <ReturnItemTransporterChip
                key={index}
                transporterInfo={info}
              />
            )
          );
        })}
      </Stack>

      {latestProcessingResult && (
        <HandlingResultChip handlingResult={latestProcessingResult.handlingResult} />
      )}

      {!returnItem!.isHandled && (
        <>
          <ReturnItemButtons
            returnItem={returnItem!}
            currentTab={ReturnItemFilter.OVERVIEW}
            onSuccess={() => {
              queryClient
                .invalidateQueries(
                  ResolutionDossiersQueryKey(ResolutionDossierStatus.AWAITING_RESPONSE)
                )
                .then();
              queryClient
                .invalidateQueries(ResolutionDossiersQueryKey(ResolutionDossierStatus.OPEN))
                .then();

              queryClient
                .invalidateQueries(ResolutionDossiersQueryKey(ResolutionDossierStatus.HANDLED))
                .then();
            }}
          />
          <AcceptOrRejectReturnItemButtons returnItem={returnItem!} />
        </>
      )}

      <UpdateReturnItemSellerCommentsButton returnItemId={returnItem!.id} />
    </Stack>
  );
};

export default ReturnResolutionCaseActions;
