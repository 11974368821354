import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import PickOrder from '../../../../domain/automator/pickSessions/PickOrder';
import { OrderItemsTableCell } from '../../orders/components/OrderItemsTableCell';
import { OrderDatesStack } from '../../orders/components/OrderDatesStack';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import { ShipmentDetailsAddress } from '../../orders/components/ShipmentDetailsAddress';
import { PickStepType } from '../../../../domain/automator/pickSessions/PickStepType';
import PickStepTypeChip from './PickStepTypeChip';
import { PickOrderApprovalStatus } from '../../../../domain/automator/pickSessions/PickOrderApprovalStatus';
import { PickStepStatus } from '../../../../domain/automator/pickSessions/PickStepStatus';
import { OrdersMode } from '../../orders/OrdersPage';
import ApprovePickOrdersButton from './ApprovePickOrdersButton';
import RejectPickOrdersButton from './RejectPickOrdersButton';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { OrderCopyButtons } from '../../orders/components/copyButtons/OrderCopyButtons';

interface PickOrderTableRowProps {
  pickOrder: PickOrder;
  isPickOrderSelected: boolean;
  onSelectPickOrder?: (pickOrderId: number) => void;
}

export const PickOrderTableRow = ({
  pickOrder,
  isPickOrderSelected,
  onSelectPickOrder,
}: PickOrderTableRowProps) => {
  const { t } = useTranslation();

  const order = pickOrder.order;

  const determineSideBarColor = () => {
    if (order.items.length > 1) {
      return 'blue';
    } else if (order.items.some((item) => item.quantity > 1)) {
      return 'blue';
    } else {
      return 'white';
    }
  };

  const determineStatus = (pickOrder: PickOrder) => {
    if (pickOrder.pickOrderApproval?.status == PickOrderApprovalStatus.REJECTED) {
      return (
        <Chip
          label={t(tokens.automator.pick_sessions.pick_order_status.rejected)}
          color="warning"
        />
      );
    }

    if (
      pickOrder.latestPickStep.type == PickStepType.FETCH_SHIPMENT &&
      pickOrder.latestPickStep.status == PickStepStatus.FINISHED
    ) {
      return (
        <Chip
          label={t(tokens.automator.pick_sessions.pick_order_status.shipped)}
          color="success"
        />
      );
    }

    if (pickOrder.latestPickStep.status == PickStepStatus.FAILED) {
      return (
        <Chip
          label={t(tokens.automator.pick_sessions.pick_order_status.failed)}
          color="warning"
        />
      );
    }

    return <PickStepTypeChip pickStepType={pickOrder.latestPickStep.type} />;
  };

  return (
    <TableRow key={order.id}>
      <TableCell sx={{ padding: 0, maxWidth: 2, position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            borderLeft: '5.5px solid ' + determineSideBarColor(),
            height: '85%',
          }}
        />
      </TableCell>

      <TableCell padding="checkbox">
        <Checkbox
          disabled={
            pickOrder.latestPickStep.type != PickStepType.APPROVAL ||
            pickOrder.pickOrderApproval?.status != PickOrderApprovalStatus.PENDING
          }
          checked={isPickOrderSelected}
          onChange={(_) => {
            onSelectPickOrder?.(pickOrder.id);
          }}
        />
      </TableCell>

      <OrderItemsTableCell
        distributionParty={order.distributionParty}
        order={order}
        mode={
          pickOrder.latestPickStep.type == PickStepType.APPROVAL &&
          pickOrder.pickOrderApproval?.status == PickOrderApprovalStatus.PENDING
            ? OrdersMode.BULK_ORDERS
            : OrdersMode.VIEW
        }
        selectedOrderItemIds={[]}
        onSelect={(_) => {}}
      />

      <TableCell>
        <OrderDatesStack order={order} />
      </TableCell>

      <TableCell>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <OrderRecipient
            shipmentDetails={order.shipmentDetails}
            isRepeatCustomer={order.repeatCustomerBolOrderIds.length > 0}
          />
          <ShipmentDetailsAddress
            pickupPoint={order.pickupPoint}
            shipmentDetails={order.shipmentDetails}
          />
        </Stack>
      </TableCell>

      <TableCell>
        {pickOrder.latestPickStep.type == PickStepType.APPROVAL &&
        pickOrder.latestPickStep.status == PickStepStatus.PENDING ? (
          <Stack
            direction="row"
            gap={1}
          >
            <ApprovePickOrdersButton
              pickSessionId={pickOrder.pickSessionId}
              selectedPickOrdersIds={[pickOrder.id]}
              showAmount={false}
            />
            <RejectPickOrdersButton
              pickSessionId={pickOrder.pickSessionId}
              selectedPickOrdersIds={[pickOrder.id]}
              showAmount={false}
            />
          </Stack>
        ) : (
          determineStatus(pickOrder)
        )}
      </TableCell>

      <TableCell>
        <OrderCopyButtons order={pickOrder.order} />
      </TableCell>
    </TableRow>
  );
};
