import Alert from '../../../../domain/automator/alerts/Alert';
import { AlertsListTableRow } from './AlertsListTableRow';

interface AlertsListTableRowsProps {
  alerts: Alert[];
}

export const AlertsListTableRows = ({ alerts }: AlertsListTableRowsProps) => {
  const groupedAlertsByOrderId = alerts!.reduce(
    (groups, alert) => {
      const orderId = alert.order.id;
      if (!groups[orderId]) {
        groups[orderId] = [];
      }
      groups[orderId]!.push(alert);
      return groups;
    },
    {} as { [key: number]: Alert[] }
  );

  return (
    <>
      {Object.entries(groupedAlertsByOrderId!).map(([orderId, alerts]) => {
        return (
          <AlertsListTableRow
            key={orderId}
            alerts={alerts}
          />
        );
      })}
    </>
  );
};
