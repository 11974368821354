import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import Typography from '@mui/material/Typography';
import MessageTemplate from '../../../../domain/automator/messages/MessageTemplate';
import { truncateString } from '../../../../helpers/truncateString';
import { useArchiveMessageTemplate } from '../../../../api/automator/emails/useArchiveMessageTemplate';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import UpdateIcon from '@mui/icons-material/EditOutlined';
import ReducedMessageTemplate from '../../../../domain/automator/eventRules/ReducedMessageTemplate';

interface MessageTemplateSelectProps {
  messageTemplate: ReducedMessageTemplate;
  onSelectMessageTemplate: (messageTemplateId: number) => void;
  selectedMessageTemplateId: number | null;
}

export const MessageTemplateSelect = ({
  messageTemplate,
  onSelectMessageTemplate,
  selectedMessageTemplateId,
}: MessageTemplateSelectProps) => {
  const { mutate: archiveMessageTemplate, isLoading: isLoadingArchive } = useArchiveMessageTemplate(
    messageTemplate.id
  );

  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.UPDATE_MESSAGE_TEMPLATE);

  const onArchive = () => {
    archiveMessageTemplate(undefined, {
      onSuccess: async () => {
        toast.success(t(tokens.automator.resolutions.dialogs.send_email.email_template_archived));
      },
    });
  };

  return (
    <Stack
      direction="row"
      onClick={() => onSelectMessageTemplate(messageTemplate.id)}
      sx={{ cursor: 'pointer' }}
      justifyContent="space-between"
    >
      <Box
        paddingY={0.5}
        paddingX={1}
        sx={{
          backgroundColor: selectedMessageTemplateId === messageTemplate.id ? '#c3c3c3' : '#efefef',
        }}
        borderRadius={1}
      >
        <Typography
          textOverflow={'ellipsis'}
          fontWeight={selectedMessageTemplateId === messageTemplate.id ? 'bold' : 'normal'}
        >
          {truncateString(messageTemplate.name, 70)}
        </Typography>
      </Box>

      <Stack direction="row">
        <ActionIcon
          icon={<UpdateIcon />}
          color="primary"
          tooltip={t(
            tokens.automator.resolutions.dialogs.update_email_template.update_email_template
          )}
          onClick={() =>
            openDialog({
              messageTemplateId: messageTemplate.id,
              onUpdate: (messageTemplate: MessageTemplate) => {
                onSelectMessageTemplate(messageTemplate.id);
              },
            })
          }
        />

        <ActionIcon
          icon={<ArchiveOutlined />}
          tooltip={t(tokens.automator.resolutions.dialogs.send_email.archive_email_template)}
          isLoading={isLoadingArchive}
          onClick={onArchive}
          color="primary"
        />
      </Stack>
    </Stack>
  );
};
