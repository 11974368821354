import * as React from 'react';
import { HubOutlined } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import SpeedDialMenuItem from './SpeeddialMenuItem';
import CommentOutlined from '@mui/icons-material/CommentOutlined';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import List from '@mui/material/List';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import Mail01Icon from '@untitled-ui/icons-react/build/esm/Mail01';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import ReplayIcon from '@mui/icons-material/Replay';

interface SpeedDialMenuProps {
  orderId: number;
  orderItemId?: number;
}

export default function SpeedDialMenu({ orderId, orderItemId }: SpeedDialMenuProps) {
  const openViewOrderSummaryDialog = useOpenDialog(DialogId.ORDER_SUMMARY);
  const openUpdateOrderNoteDialog = useOpenDialog(DialogId.UPDATE_ORDER_NOTE_DIALOG);
  const openUpdateOffersDialog = useOpenDialog(DialogId.UPDATE_OFFERS);
  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);
  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);

  const { data: order, isLoading: isOrderLoading } = useFetchOrder(orderId);

  const { data: shipment, isLoading: isShipmentLoading } = useFetchOrderShipment(orderId);

  if (isOrderLoading || isShipmentLoading) {
    return <></>;
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <SpeedDialMenuItem
        icon={<HubOutlined />}
        label={tokens.automator.order_summary.order_summary}
        onClick={() => openViewOrderSummaryDialog({ query: order!.bolOrderId })}
      />

      <SpeedDialMenuItem
        icon={<Mail01Icon />}
        label={tokens.automator.resolutions.dialogs.send_email.dialog_title}
        onClick={() =>
          openCreateEmailThreadDialog({
            orderId: orderId,
            phase: null,
            aggregateId: null,
            body: null,
            messageTemplateId: null,
            recipientType: RecipientType.CUSTOMER,
          })
        }
      />

      {orderItemId && (
        <SpeedDialMenuItem
          icon={<PriceChangeIcon />}
          label={tokens.automator.orders.dialogs.update_offer.update_offers}
          onClick={() =>
            openUpdateOffersDialog({
              productId: order!.items.find((item) => item.id === orderItemId)!.offer.product.id,
            })
          }
        />
      )}

      <SpeedDialMenuItem
        icon={<CommentOutlined />}
        label={tokens.automator.orders.dialogs.update_order_note.note}
        onClick={() => openUpdateOrderNoteDialog({ orderId })}
      />

      {shipment && (
        <SpeedDialMenuItem
          icon={<ReplayIcon />}
          label={tokens.automator.resolutions.dialogs.create_resend.create_resend}
          onClick={() => openCreateResendDialog({ orderId })}
        />
      )}
    </List>
  );
}
