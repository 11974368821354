import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import ActionButton from '../../../../components/ActionButton';
import { ActionType } from '../../orders/components/copyButtons/ActionType';
import { ActionIcon } from '../../orders/components/ActionIcon';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { tokens } from '../../../../locales/translationTokens';
import * as React from 'react';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';

interface OpenStartShipmentEmailThreadDialogButtonProps {
  orderId: number;
  shipmentId: number;
  body: string | null;
  type: ActionType;
}

export const OpenStartShipmentEmailThreadDialogButton = ({
  orderId,
  shipmentId,
  body,
  type,
}: OpenStartShipmentEmailThreadDialogButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const icon = <ForwardToInboxOutlinedIcon />;
  const label = tokens.automator.resolutions.dialogs.send_email.forward_email;

  const onClick = () => {
    openDialog({
      aggregateId: shipmentId,
      orderId: orderId,
      messageTemplateId: null,
      phase: ThreadPhase.SHIPMENT,
      body: body,
      recipientType: RecipientType.CUSTOMER,
    });
  };

  return type === ActionType.BUTTON ? (
    <ActionButton
      size="small"
      icon={icon}
      label={t(label)}
      onClick={onClick}
    />
  ) : (
    <ActionIcon
      icon={icon}
      tooltip={t(label)}
      onClick={onClick}
    />
  );
};
