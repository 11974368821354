import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import ActionButton from '../../../../components/ActionButton';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';

interface OpenStartEmailThreadDialogButtonProps {
  orderId: number;
  icon: React.ReactNode;
  label: string;
  body: string | null;
}

export const OpenStartEmailThreadDialogButton = ({
  orderId,
  icon,
  label,
  body,
}: OpenStartEmailThreadDialogButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  return (
    <ActionButton
      icon={icon}
      label={t(label)}
      size="small"
      onClick={() =>
        openDialog({
          aggregateId: null,
          orderId: orderId,
          phase: null,
          body: body,
          messageTemplateId: null,
          recipientType: RecipientType.CUSTOMER,
        })
      }
    />
  );
};
