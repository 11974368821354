import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PickSession from '../../../../domain/automator/pickSessions/PickSession';
import { PickSessionProgressView } from './PickSessionProgressView';
import { PickSessionDownloads } from './PickSessionDownloads';
import LabelGenerationChip from './LabelGenerationChip';
import TransporterChip from '../../shipments/components/TransporterChip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LabelGeneration } from '../../../../domain/automator/pickSessions/LabelGeneration';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { TransporterCode } from '../../../../domain/automator/shipments/TransporterCode';

interface PickSessionTableRowProps {
  pickSession: PickSession;
  onClick: (pickSessionId: number) => void;
}

export const PickSessionTableRow = ({ pickSession, onClick }: PickSessionTableRowProps) => {
  const hasPostNl = pickSession.transporterCodes.some(
    (transporterCode) =>
      transporterCode === TransporterCode.TNT_BRIEF || transporterCode === TransporterCode.TNT
  );

  const transporterCodes = pickSession.transporterCodes.filter((transporterCode) => {
    return transporterCode != TransporterCode.TNT_BRIEF && transporterCode != TransporterCode.TNT;
  });

  if (hasPostNl) {
    transporterCodes.push(TransporterCode.TNT);
  }

  return (
    <TableRow onClick={() => onClick(pickSession.id)}>
      <TableCell align="left">
        <LabelGenerationChip labelGeneration={pickSession.labelGeneration} />
      </TableCell>

      <TableCell align="left">
        <Stack
          direction="row"
          gap={2}
        >
          <Typography
            width={155}
            variant="body2"
          >
            {pickSession.createdDateTime}
          </Typography>

          {transporterCodes.map((transporterCode) => (
            <TransporterChip
              key={transporterCode}
              transporterCode={transporterCode}
              variant="filled"
              distributionParty={
                pickSession.labelGeneration == LabelGeneration.VVB
                  ? DistributionParty.BOL
                  : DistributionParty.RETAILER
              }
              withColor
            />
          ))}
        </Stack>
      </TableCell>

      <TableCell align="left">
        <PickSessionProgressView progress={pickSession.progress} />
      </TableCell>
      <TableCell align={'right'}>
        <PickSessionDownloads pickSession={pickSession} />
      </TableCell>
    </TableRow>
  );
};
