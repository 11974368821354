import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '../../../../domain/automator/alerts/Alert';
import Stack from '@mui/material/Stack';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { AlertEventTriggerInfo } from './AlertEventTriggerInfo';
import { ExecuteAllAlertActionsButton } from './ExecuteAllAlertActionsButton';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';

interface AlertsListTableEventTriggerGroupRowProps {
  eventTrigger: EventTrigger;
  alerts: Alert[];
  onClick: () => void;
}

export const AlertsListTableEventTriggerGroupRow = ({
  alerts,
  eventTrigger,
  onClick,
}: AlertsListTableEventTriggerGroupRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      hover
      onClick={onClick}
    >
      <TableCell width={350}>
        <AlertEventTriggerInfo
          eventTrigger={eventTrigger}
          distributionParty={null}
        />
      </TableCell>

      <TableCell>
        <Typography
          style={{ fontSize: 17, fontWeight: 500 }}
          variant="body1"
        >
          {alerts.length + ' ' + t(tokens.automator.alerts.alerts)}
        </Typography>
      </TableCell>

      <TableCell />

      <TableCell>
        <Stack
          direction="row"
          gap={3}
          justifyContent={'flex-end'}
        >
          <ExecuteAllAlertActionsButton alerts={alerts} />
          <DismissAlertsActionIcon alertIds={alerts.map((alert) => alert.id)} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
