import { useTranslation } from 'react-i18next';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { tokens } from '../../../../locales/translationTokens';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { toast } from 'react-hot-toast';
import { useDismissAlerts } from '../../../../api/automator/alerts/useDismissAlerts';

interface DismissAlertsActionIconProps {
  alertIds: number[];
}

export const DismissAlertsActionIcon = ({ alertIds }: DismissAlertsActionIconProps) => {
  const { t } = useTranslation();

  const { mutate: dismissAlert, isLoading: islLoadingDismissAlerts } = useDismissAlerts();

  const onDismiss = () => {
    dismissAlert(
      { alert_ids: alertIds },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.alerts.dismissed));
        },
      }
    );
  };

  return (
    <ActionIcon
      iconSize={30}
      icon={<CancelOutlinedIcon />}
      tooltip={t(tokens.automator.alerts.dismiss)}
      isLoading={islLoadingDismissAlerts}
      onClick={onDismiss}
    />
  );
};
