import { Tooltip } from '@mui/material';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';

const DoublePlaceOrderChip = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      enterDelay={2000}
      enterNextDelay={2000}
      title={t(tokens.automator.orders.double_placed_order_tooltip)}
      placement={'right'}
    >
      <Chip
        size="small"
        label={t(tokens.automator.orders.double_placed_order)}
        color="warning"
        variant="filled"
      />
    </Tooltip>
  );
};

export default DoublePlaceOrderChip;
