import { TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OrderDayReport from '../../../../domain/automator/orders/OrderDayReport';
import Order from '../../../../domain/automator/orders/Order';
import getWeekNumberOfYear from '../../../../helpers/getWeekNumberOfYear';
import getDayOfTheWeek from 'src/helpers/getDayOfTheWeek';
import Account from '../../../../domain/automator/account/Account';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import OrdersPerDayRow from './OrdersPerDayRow';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { OrdersMode } from '../OrdersPage';

interface OrdersDayReportRowProps {
  account: Account;
  startDate: string;
  endDate: string;
  orders?: Order[];
  ordersDayReport?: OrderDayReport;
  isExpanded: boolean;
  onExpand: (startDate: string, endDate: string) => void;
  isLoading: boolean;
  distributionParty: DistributionParty;
  selectedCountries: CountryCode[];
  mode: OrdersMode;
}

const OrdersDayReportRow = ({
  startDate,
  endDate,
  orders,
  ordersDayReport,
  isExpanded,
  onExpand,
  isLoading,
  account,
  distributionParty,
  selectedCountries,
  mode,
}: OrdersDayReportRowProps) => {
  const { t } = useTranslation();

  const totalShippedOrders = ordersDayReport ? ordersDayReport.totalShipped : 0;

  const dayOfTheWeek = getDayOfTheWeek(startDate);

  const weekNumberOfYear = getWeekNumberOfYear(startDate);

  const paddingHorizontal = totalShippedOrders > 0 ? 10 : 15;

  return (
    <>
      <TableRow
        hover={true}
        style={{
          cursor: totalShippedOrders > 0 ? 'pointer' : undefined,
          backgroundColor: isExpanded && totalShippedOrders > 0 ? '#F8F9FA' : 'white',
        }}
        onClick={() => totalShippedOrders > 0 && onExpand(startDate, endDate)}
      >
        <TableCell
          style={{ paddingTop: paddingHorizontal, paddingBottom: paddingHorizontal }}
          width={40}
        >
          <Typography variant="subtitle1">{t(dayOfTheWeek).toUpperCase()}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">
            W{weekNumberOfYear} {startDate}
          </Typography>
        </TableCell>

        <TableCell
          style={{
            paddingTop: paddingHorizontal,
            paddingBottom: paddingHorizontal,
          }}
          align="left"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={10}
          >
            {totalShippedOrders == 0 ? (
              <Typography variant="body2">
                {t(tokens.automator.orders.no_shipped_orders_this_days)}
              </Typography>
            ) : (
              <Typography>
                {totalShippedOrders} {t(tokens.automator.orders.shipped_orders)}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell
          style={{ paddingTop: paddingHorizontal, paddingBottom: paddingHorizontal }}
          align="right"
        >
          {totalShippedOrders != 0 && <IconButton>{isExpanded ? '-' : '+'}</IconButton>}
        </TableCell>
      </TableRow>

      {isExpanded && totalShippedOrders > 0 && (
        <OrdersPerDayRow
          isExpanded={isExpanded}
          account={account}
          distributionParty={distributionParty}
          isLoading={isLoading}
          orders={orders}
          selectedCountries={selectedCountries}
          mode={mode}
        />
      )}

      {isExpanded && totalShippedOrders > 0 && (
        <TableRow style={{ backgroundColor: '#F8F9FA' }}>
          <TableCell colSpan={5}></TableCell>
        </TableRow>
      )}
    </>
  );
};

export default OrdersDayReportRow;
