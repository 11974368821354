import Stack from '@mui/material/Stack';
import { EventRuleInfoButton } from './EventRuleInfoButton';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { Box } from '@mui/system';
import EventRuleTriggerChip from '../../resolutions/components/EventRuleTriggerChip';

interface AlertEventTriggerInfoProps {
  eventTrigger: EventTrigger;
  distributionParty: DistributionParty | null;
}

export const AlertEventTriggerInfo = ({
  distributionParty,
  eventTrigger,
}: AlertEventTriggerInfoProps) => {
  return (
    <Stack
      direction="row"
      gap={0.25}
      alignItems="left"
    >
      <Box>
        <EventRuleTriggerChip trigger={eventTrigger} />
      </Box>
      <Box>
        <EventRuleInfoButton
          eventTrigger={eventTrigger}
          distributionParty={distributionParty}
        />
      </Box>
    </Stack>
  );
};
