import Alert from '../../../../domain/automator/alerts/Alert';
import Stack from '@mui/material/Stack';
import { SuggestedAction } from './SuggestedAction';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';
import { ExecuteAllAlertActionsButton } from './ExecuteAllAlertActionsButton';

interface AlertActionsProps {
  alerts: Alert[];
}

export const AlertActions = ({ alerts }: AlertActionsProps) => {
  const [isActionsHidden, setIsActionsHidden] = useState(alerts.length > 1);

  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      gap={1}
      alignItems="flex-end"
    >
      {alerts.length > 1 && (
        <Stack
          gap={3}
          direction={'row'}
        >
          <Box width={190}>
            <Button
              onClick={() => setIsActionsHidden(!isActionsHidden)}
              color="primary"
              variant="outlined"
              size="small"
            >
              {alerts.length + ' ' + t(tokens.automator.alerts.actions)}
              {isActionsHidden ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
          </Box>

          <ExecuteAllAlertActionsButton alerts={alerts} />

          <DismissAlertsActionIcon alertIds={alerts.map((alert) => alert.id)} />
        </Stack>
      )}

      {!isActionsHidden &&
        alerts.map((alert) => (
          <SuggestedAction
            key={alert.id}
            alert={alert}
          />
        ))}
    </Stack>
  );
};
