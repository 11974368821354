import Stack from '@mui/material/Stack';
import { OrderItemProductInfo } from '../../orders/components/OrderItemProductInfo';
import Radio from '@mui/material/Radio';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';

interface EmailThreadOrderItemSelectionProps {
  order: ReducedOrder;
  setAggregateId: (aggregateId: number) => void;
  selectedAggregateId: number | null;
}

export const EmailThreadOrderItemSelection = ({
  order,
  setAggregateId,
  selectedAggregateId,
}: EmailThreadOrderItemSelectionProps) => {
  return (
    <Stack
      direction="column"
      gap={1}
    >
      {order.items.map((orderItem, index) => (
        <Stack
          key={index}
          direction="row"
          gap={2}
        >
          <Radio
            checked={selectedAggregateId === orderItem.id}
            onChange={() => setAggregateId(orderItem.id)}
          />
          <OrderItemProductInfo
            orderStatus={order.status}
            orderItemStatus={orderItem.status}
            quantity={orderItem.quantity}
            offer={orderItem.offer}
          />
        </Stack>
      ))}
    </Stack>
  );
};
