import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { HandlingResult } from '../../../../domain/automator/returns/HandlingResult';
import { RetailerDropdown } from './RetailerDropdown';
import { HandlingResultDropdown } from './HandlingResultDropdown';
import { toast } from 'react-hot-toast';
import { useCreateReturn } from '../../../../api/automator/returns/useCreateReturn';
import Stack from '@mui/material/Stack';

interface CreateReturnFormProps {
  onClick?: () => void;
}

export const CreateReturnForm = ({ onClick }: CreateReturnFormProps) => {
  const [retailerId, setRetailerId] = useState<number | null>(null);
  const [bolOrderId, setBolOrderId] = useState<string | null>(null);
  const [bolOrderItemId, setBolOrderItemId] = useState<string | null>(null);
  const [quantityReturned, setQuantityReturned] = useState<number>(1);

  const [handlingResult, setHandlingResult] = useState(HandlingResult.RETURN_RECEIVED);

  const { t } = useTranslation();

  const { mutate: createReturn, isLoading } = useCreateReturn();

  const onCreate = () => {
    createReturn(
      {
        retailer_id: retailerId!,
        bol_order_id: bolOrderId!,
        bol_order_item_id: bolOrderItemId!,
        handling_result: handlingResult,
        quantity_returned: quantityReturned,
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.returns.dialogs.create_return.return_created));
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="column"
        gap={3}
      >
        <RetailerDropdown
          value={retailerId}
          onSelect={setRetailerId}
        />

        <TextField
          id="subject-field"
          label={t(tokens.automator.returns.dialogs.create_return.bol_order_id)}
          variant="filled"
          fullWidth
          value={bolOrderId}
          onChange={(e) => setBolOrderId(e.target.value)}
        />

        <TextField
          id="subject-field"
          label={t(tokens.automator.returns.dialogs.create_return.bol_order_item_id)}
          variant="filled"
          fullWidth
          value={bolOrderItemId}
          onChange={(e) => setBolOrderItemId(e.target.value)}
        />

        <TextField
          id="subject-field"
          label={t(tokens.automator.returns.dialogs.create_return.quantity_returned)}
          variant="filled"
          fullWidth
          value={quantityReturned}
          onChange={(e) => setQuantityReturned(parseInt(e.target.value))}
        />

        <HandlingResultDropdown
          value={handlingResult}
          onSelect={setHandlingResult}
        />

        <ActionButton
          label={t(tokens.automator.returns.dialogs.create_return.dialog_title)}
          onClick={() => onCreate()}
          isLoading={isLoading}
          disabled={!retailerId || !bolOrderId || !bolOrderItemId}
          variant="contained"
          color="primary"
          size="small"
        />
      </Stack>
    </form>
  );
};
