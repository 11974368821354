import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { OrderStatus } from '../../../../domain/automator/orders/OrderStatus';
import { OrderTableRow } from './OrderTableRow';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import ApplicationError from '../../../../domain/automator/ApplicationError';
import OrdersErrorMessage from './OrdersErrorMessage';
import Order from '../../../../domain/automator/orders/Order';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../../domain/automator/orders/CountryCode';
import { TransporterCode } from '../../../../domain/automator/shipments/TransporterCode';
import { useOrderTab } from '../../../../store/orderTabs/useOrderTab';
import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrdersMode } from '../OrdersPage';
import { BulkOrderActions } from './BulkOrderActions';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import Stack from '@mui/material/Stack';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';

interface OrderListTableProps {
  orders?: Order[];
  isLoading: boolean;
  error?: ApplicationError | null;
  isError: boolean;
  distributionParty: DistributionParty;
  selectedCountries: CountryCode[];
  top3TransporterCodes?: TransporterCode[];
  mode: OrdersMode;
}

export const OrderListTable = ({
  orders,
  isError,
  error,
  isLoading,
  distributionParty,
  selectedCountries,
  top3TransporterCodes,
  mode,
}: OrderListTableProps) => {
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);

  const [selectedOrderItems, setSelectedOrderItems] = useState<OrderItem[]>([]);

  const { orderTab: currentTab } = useOrderTab();

  const { t } = useTranslation();

  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  useEffect(() => {
    setSelectedOrders([]);
  }, [distributionParty]);

  if (isError) {
    return <OrdersErrorMessage error={error! as unknown as ApplicationError} />;
  }

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  const filteredOrders = orders!.filter((order) => {
    if (selectedCountries.length == 2 || selectedCountries.length == 0) {
      // NL + BE
      return true;
    } else if (selectedCountries.includes(CountryCode.BE)) {
      return order.shipmentDetails.countryCode == CountryCode.BE;
    } else if (selectedCountries.includes(CountryCode.NL)) {
      return order.shipmentDetails.countryCode == CountryCode.NL;
    }
  });

  const ordersThatCanBeProcessed = filteredOrders!.filter((order) => {
    return order.status == OrderStatus.OPEN || order.status == OrderStatus.FAILED;
  });

  if (filteredOrders!.length == 0) {
    return <TableEmpty message={t(tokens.automator.orders.no_items)} />;
  }

  const onSelectAllOrders = () => {
    setSelectedOrders(
      ordersThatCanBeProcessed.map((order) => {
        return order;
      })
    );
  };

  const onDeselectAllOrders = () => {
    setSelectedOrders([]);
  };

  const onSelectOrder = (order: Order) => {
    setSelectedOrders([...new Set(selectedOrders), order]);
  };

  const onDeSelectOrder = (orderId: number) => {
    setSelectedOrders([...new Set(selectedOrders.filter((item) => item.id != orderId))]);
  };

  const onSelectOrderItem = (orderItem: OrderItem) => {
    //if present remove
    if (selectedOrderItems.includes(orderItem)) {
      setSelectedOrderItems([
        ...new Set(selectedOrderItems.filter((item) => item.id != orderItem.id)),
      ]);
      return;
    } else {
      setSelectedOrderItems([...new Set(selectedOrderItems), orderItem]);
    }
  };

  const selectedAllOrders =
    selectedOrders.length == ordersThatCanBeProcessed.length && selectedOrders.length > 0;
  const selectedSomeNotAllOrders =
    selectedOrders.length > 0 && selectedOrders.length < ordersThatCanBeProcessed.length;
  const selectedSomeOrders = selectedSomeNotAllOrders || selectedAllOrders;

  return (
    <Box sx={{ position: 'relative' }}>
      {mode == OrdersMode.BULK_ORDERS && selectedSomeOrders && (
        <BulkOrderActions
          selectedOrders={selectedOrders.map((selectedOrder) => {
            return filteredOrders!.find((order) => order.id == selectedOrder.id)!;
          })}
          selectedSomeOrders={selectedSomeOrders}
          selectedSomeNotAllOrders={selectedSomeNotAllOrders}
          selectedAllOrders={selectedAllOrders}
          onSelectAll={onSelectAllOrders}
          onDeselectAll={onDeselectAllOrders}
          distributionParty={distributionParty}
          top3TransporterCodes={top3TransporterCodes}
        />
      )}

      {mode == OrdersMode.BULK_ORDER_ITEMS && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            //@TODO to tss
            alignItems: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50',
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            paddingLeft: '18px',
            py: 1,
            zIndex: 10,
          }}
        >
          <ActionButton
            size="small"
            label={t(
              selectedOrderItems.length == 0
                ? tokens.automator.orders.select_items
                : tokens.automator.orders.bulk_mail
            )}
            onClick={() => {
              if (selectedOrderItems.length == 0) return;

              openCreateEmailThreadDialog({
                phase: ThreadPhase.ORDER,
                aggregateId: null,
                aggregateIds: selectedOrderItems.map((item) => item.id),
                body: null,
                orderId: null,
                messageTemplateId: null,
                recipientType: RecipientType.CUSTOMER,
              });

              setSelectedOrderItems([]);
            }}
          />
        </Stack>
      )}

      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {(mode == OrdersMode.BULK_ORDERS || mode == OrdersMode.BULK_ORDER_ITEMS) && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllOrders}
                    indeterminate={selectedSomeNotAllOrders}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onSelectAllOrders();
                      } else {
                        onDeselectAllOrders();
                      }
                    }}
                  />
                </TableCell>
              )}
              <TableCell>{t(tokens.automator.orders.order_items)}</TableCell>
              <TableCell>{t(tokens.automator.orders.bol_order_id)}</TableCell>
              <TableCell>{t(tokens.automator.orders.recipient)}</TableCell>
              {distributionParty == DistributionParty.BOL && (
                <TableCell>{t(tokens.automator.label_type.label_type)}</TableCell>
              )}
              {currentTab == OrderStatus.SHIPPED && (
                <TableCell>{t(tokens.automator.orders.shipment_date_time)}</TableCell>
              )}

              {currentTab == OrderStatus.SHIPPED && <TableCell></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredOrders!.map((order, index) => {
              return (
                <OrderTableRow
                  key={index}
                  order={order}
                  mode={mode}
                  isOrderSelected={
                    selectedOrders.find((selectedOrder) => {
                      return selectedOrder.id == order.id;
                    }) != undefined
                  }
                  onSelectOrder={onSelectOrder}
                  onDeselectOrder={onDeSelectOrder}
                  selectedOrderItemIds={selectedOrderItems.map((item) => item.id)}
                  onSelectOrderItem={onSelectOrderItem}
                  distributionParty={distributionParty}
                />
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
