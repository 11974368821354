import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { useTranslation } from 'react-i18next';
import { TableEmpty } from '../../../../components/TableEmpty';
import { useState } from 'react';
import ResolutionDossier from '../../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionDossierTableRow } from './ResolutionDossierTableRow';
import { TableListDivider } from '../../returns/components/TableListDivider';
import Pagination from '../../../../domain/Pagination';
import TablePagination from '@mui/material/TablePagination';
import { ResolutionDossierStatus } from '../../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';

interface ResolutionDossierListTableProps {
  resolutionDossiers?: ResolutionDossier[];
  isLoading: boolean;
  pagination: Pagination | null;
  onPageChange?: (page: number) => void;
}

export const ResolutionDossierListTable = ({
  resolutionDossiers,
  isLoading,
  pagination,
  onPageChange,
}: ResolutionDossierListTableProps) => {
  const [selectedDossierId, setSelectedDossierId] = useState<number | null>(null);

  const { t } = useTranslation();

  if (isLoading || !resolutionDossiers) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (resolutionDossiers!.length == 0) {
    return <TableEmpty message={t(tokens.automator.resolutions.no_items)} />;
  }

  const onSelect = (id: number | null) => {
    setSelectedDossierId(id);
  };

  const groupDossiersByDate = (dossiers: ResolutionDossier[]) => {
    const getLatestDate = (dossier: ResolutionDossier): Date => {
      if (dossier.status == ResolutionDossierStatus.HANDLED && dossier.updatedDateTime) {
        return new Date(dossier.updatedDateTime);
      }

      return dossier.resolutionCases.reduce((latestDate, resolutionCase) => {
        const caseLatestReplyDate = resolutionCase?.latestReplyDate
          ? new Date(resolutionCase.latestReplyDate)
          : new Date(0); // Default low-earliest date

        const caseCreatedDate = resolutionCase?.createdDate
          ? new Date(resolutionCase.createdDate)
          : new Date(0); // Default low-earliest date

        const caseMaxDate =
          caseLatestReplyDate > caseCreatedDate ? caseLatestReplyDate : caseCreatedDate;

        return caseMaxDate > latestDate ? caseMaxDate : latestDate;
      }, new Date(0)); // Default low-earliest date in reduce
    };

    const groupMap = new Map<string, ResolutionDossier[]>();

    dossiers.forEach((dossier) => {
      const latestDate = getLatestDate(dossier);
      const dateKey = latestDate.toISOString().split('T')[0];
      if (!groupMap.has(dateKey)) {
        groupMap.set(dateKey, []);
      }
      const group = groupMap.get(dateKey)!;
      group.push(dossier);
    });
    return groupMap;
  };

  const groupedDossiers = groupDossiersByDate(resolutionDossiers);

  const sortedGroupedDossiers = new Map(
    Array.from(groupedDossiers).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())
  );
  //
  // const isAwaitingResponseOnReply = (resolutionDossier: ResolutionDossier) => {
  //   return resolutionDossier.resolutionCases.some((resolutionCase) => {
  //     return resolutionCase.isAWaitingResponseOnReply;
  //   });
  // };

  return (
    <Scrollbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={10} />
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(sortedGroupedDossiers).map(([date, resolutionDossiers], index) => {
            return [
              <TableListDivider
                key={'divider-' + index}
                label={date}
              />,
              ...resolutionDossiers
                // .sort((a, b) => {
                //   const awaitingA = isAwaitingResponseOnReply(a);
                //   const awaitingB = isAwaitingResponseOnReply(b);
                //   return awaitingA === awaitingB ? 0 : awaitingA ? 1 : -1;
                // })
                .map((resolutionDossier, index) => {
                  return (
                    <ResolutionDossierTableRow
                      isFirst={index == 0}
                      isLast={index == resolutionDossiers.length - 1}
                      key={resolutionDossier.id}
                      resolutionDossier={resolutionDossier}
                      isSelected={selectedDossierId === resolutionDossier.id}
                      setSelectedDossierId={onSelect}
                    />
                  );
                }),
            ];
          })}
        </TableBody>

        {pagination && onPageChange && (
          <TablePagination
            component="div"
            count={pagination.itemsCount} // Make sure you provide the correct total count here
            page={pagination.page - 1}
            onPageChange={(_, page: number) => onPageChange(page + 1)}
            rowsPerPage={pagination.pageSize}
            rowsPerPageOptions={[]} // Disable the option to change rows per page
          />
        )}
      </Table>
    </Scrollbar>
  );
};
