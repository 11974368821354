import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';
import SpeedDialActionIcon from '../../shared/speeddial/SpeedDialActionIcon';

interface CondensedOrderViewProps {
  order: ReducedOrder;
}

export const CondensedOrderView = ({ order }: CondensedOrderViewProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
      >
        {order.items.map((item, index) => {
          return (
            <TooltippedProductThumbnail
              key={index}
              productTitle={item.offer.product.title}
              height={60}
              thumbnailUrl={item.offer.product.thumbnailUrl}
              quantity={item.quantity}
            />
          );
        })}

        <Stack direction="column">
          <Stack
            direction="row"
            gap={1}
          >
            <DistributionPartyChip distributionParty={order.distributionParty} />
            <OrderRecipient
              shipmentDetails={order.shipmentDetails}
              isRepeatCustomer={false} // don't want to display this
            />
          </Stack>

          <Stack
            direction="row"
            gap={0.5}
          >
            <RetailerLooksIcon retailerId={order.retailerId} />
            <Typography variant="body2">{order.bolOrderId}</Typography>
          </Stack>
        </Stack>
      </Stack>
      
      <SpeedDialActionIcon
        orderId={order.id}
        hasNote={order.hasNote}
      />
    </Stack>
  );
};
