import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import EventRule from '../../../../domain/automator/eventRules/EventRule';
import { AutoMailEventRuleTableRow } from './AutoMailEventRuleTableRow';

interface AutoMailEventRulesListTableProps {
  isLoading: boolean;
  eventRules?: EventRule[] | null;
}

export const AutoMailEventRulesListTable = ({
  eventRules,
  isLoading,
}: AutoMailEventRulesListTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (eventRules!.length == 0) {
    return <TableEmpty message={t(tokens.automator.auto_mail.no_items)} />;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t(tokens.automator.auto_mail.name)}</TableCell>
              <TableCell>{t(tokens.automator.auto_mail.product)}</TableCell>
              <TableCell />
              <TableCell>{t(tokens.automator.auto_mail.conditions)}</TableCell>
              <TableCell>{t(tokens.automator.auto_mail.delay_type.delay)}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {eventRules!
              .sort((a, b) => {
                return a.isDisabled < b.isDisabled ? -1 : 1;
              })
              .map((eventRule) => {
                return (
                  <AutoMailEventRuleTableRow
                    key={eventRule.id}
                    eventRule={eventRule}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
