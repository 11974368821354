import { ALERTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useAlertQueryKeysUpdater } from './useAlertQueryKeysUpdater';
import Alerts from '../../../domain/automator/alerts/Alerts';

export const useExecuteAlerts = () => {
  const updater = useAlertQueryKeysUpdater();
  const updateData = useUpdateData(updater, 'alerts');

  const onSuccess = (alerts: Alerts) => {
    updateData(alerts);
  };

  const url = useAutomatorApiAccountUrl(ALERTS_PATH + '/execute-actions');
  return useAuthorizedPost<Alerts, ExecuteAlertData>(url, [], undefined, onSuccess);
};

export interface ExecuteAlertData {
  alert_ids: number[];
}
