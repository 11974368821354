import Stack from '@mui/material/Stack';
import { tokens } from '../../../../locales/translationTokens';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';
import Button from '@mui/material/Button';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';

interface OrderSummaryRecurringOrdersCardItemProps {
  recurringBolOrderIds: string[];
  placedDoubleBolOrderIds: string[];
}

export const OrderSummaryRecurringOrdersCardItem = ({
  recurringBolOrderIds,
  placedDoubleBolOrderIds,
}: OrderSummaryRecurringOrdersCardItemProps) => {
  const openDialog = useOpenDialog(DialogId.ORDER_SUMMARY);

  const bolOrderIds = recurringBolOrderIds.concat(placedDoubleBolOrderIds);

  if (bolOrderIds.length === 0) {
    return <></>;
  }

  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.recurring_orders}>
      <Stack
        direction="column"
        gap={1}
      >
        {bolOrderIds.map((recurringBolOrderId) => {
          return (
            <Button
              onClick={() => openDialog({ query: recurringBolOrderId })}
              key={recurringBolOrderId}
              variant="outlined"
              size="small"
            >
              {recurringBolOrderId}
            </Button>
          );
        })}
      </Stack>
    </OrderSummaryCardItem>
  );
};
