import Badge from '@mui/material/Badge';

interface AwaitingAlertsBadge {
  amountOfAwaitingAlerts: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AwaitingAlertsBadge = ({ amountOfAwaitingAlerts }: AwaitingAlertsBadge) => {
  if (amountOfAwaitingAlerts === 0) {
    return '';
  }

  return (
    <Badge
      badgeContent={'+' + amountOfAwaitingAlerts}
      color="info"
    ></Badge>
  );
};
