import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { EmailDocumentsList } from './EmailDocumentsList';
import HtmlEditor from '../../autoEmail/components/HtmlEditor';
import { RecipientTypeToggleButtonGroup } from './RecipientTypeToggleButtonGroup';
import Box from '@mui/material/Box';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { useGetEmailMessageDraft } from '../../../../store/emailMessageDrafts/useGetEmailMessageDraft';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { EmailMessageDraft } from '../../../../store/emailMessageDrafts/emailMessageDraftSlice';
import {
  CreateEmailThreadData,
  useCreateEmailThreads,
} from '../../../../api/automator/emails/useCreateEmailThreads';
import { toast } from 'react-hot-toast';
import { EmailThreadAggregateSelection } from './EmailThreadAggregateSelection';
import { TemplateVariableSelection } from './TemplateVariableSelection';
import Chip from '@mui/material/Chip';
import FileUpload from '../../shared/FileUpload';
import FileUploadForm from './FileUploadForm';
import { useFetchMessageTemplate } from '../../../../api/automator/emails/useFetchMessageTemplate';
import { useDismissAlerts } from '../../../../api/automator/alerts/useDismissAlerts';
import { useUpdateMessageTemplate } from '../../../../api/automator/emails/useUpdateMessageTemplate';

interface StartEmailThreadFormProps {
  orderId: number | null;
  alertId?: number;
  onClick: () => void;
  phase: ThreadPhase | null;
  aggregateId: number | null;
  aggregateIds: number[];
  messageTemplateId: number | null;
  body: string | null;
  mode: StartEmailThreadMode;
  recipientType: RecipientType;
}

export enum StartEmailThreadMode {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
}

export const StartEmailThreadForm = ({
  orderId,
  alertId,
  phase: initialPhase,
  aggregateId: initialAggregateId,
  aggregateIds,
  onClick,
  body,
  mode,
  messageTemplateId: initialMessageTemplateId,
  recipientType: initialRecipientType,
}: StartEmailThreadFormProps) => {
  const { t } = useTranslation();
  const [aggregateId, setAggregateId] = useState<number | null>(initialAggregateId);
  const [phase, setPhase] = useState<ThreadPhase>(initialPhase || ThreadPhase.ORDER);
  const [aggregateIdError, setAggregateIdError] = useState(false);

  const [insertVariable, setInsertVariable] = useState<string | null>(null);

  const [recipientType, setRecipientType] = useState<RecipientType>(initialRecipientType);

  const { emailMessageDraft, setEmailMessageDraft } = useGetEmailMessageDraft(
    aggregateId,
    phase,
    recipientType
  );

  const [messageTemplateId, setMessageTemplateId] = useState(initialMessageTemplateId);

  const { data: messageTemplate } = useFetchMessageTemplate(messageTemplateId);

  const { mutate: dismissAlert } = useDismissAlerts();

  const { mutate: updateMessageTemplate, isLoading: isLoadingUpdateMessageTemplate } =
    useUpdateMessageTemplate(messageTemplateId ?? 0);

  useEffect(() => {
    if (messageTemplate) {
      setEmailMessageDraft({
        ...draft,
        messageTemplateId: messageTemplate.id,
        body: messageTemplate?.body,
        subject: messageTemplate.subject,
      });
    }
  }, [messageTemplate]);

  const determineBody = (newRecipientType: RecipientType) => {
    if (newRecipientType == RecipientType.CUSTOMER) {
      return `Beste [[CUSTOMER_NAME]],<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    }

    if (newRecipientType == RecipientType.BOL) {
      return `Beste Bol,<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Winkelnummer: [[BOL_RETAILER_ID]]`;
    }

    return '';
  };

  const determineSubject = (newRecipientType: RecipientType) => {
    if (newRecipientType == RecipientType.BOL) {
      return '[[BOL_ORDER_ID]] ';
    }

    return '';
  };

  const getDraft = () => {
    if (emailMessageDraft) {
      return emailMessageDraft;
    } else {
      return {
        recipientType: recipientType,
        phase: phase,
        aggregateId: aggregateId,
        messageTemplateId: null,
        subject: determineSubject(recipientType),
        body: body || determineBody(recipientType),
        startWithCase: true,
        files: [],
      };
    }
  };

  const onUpdatePhase = (phase: ThreadPhase) => {
    setEmailMessageDraft({
      ...draft,
      phase,
      aggregateId: null,
    });
    setPhase(phase);
    setAggregateId(null);
  };

  const onUpdateRecipientType = (recipientType: RecipientType) => {
    setEmailMessageDraft({
      ...draft,
      body: draft.messageTemplateId != null ? draft.body : determineBody(recipientType),
      subject: draft.messageTemplateId != null ? draft.subject : determineSubject(recipientType),
      recipientType,
    });

    setRecipientType(recipientType);
  };

  const { mutate: createEmailThreads, isLoading } = useCreateEmailThreads(getDraft().phase);

  const canChangePhase = initialPhase == null;

  const draft = getDraft();

  const onCreate = (emailMessageDraft: EmailMessageDraft) => {
    if (mode == StartEmailThreadMode.SINGLE && !emailMessageDraft.aggregateId) {
      toast.error(
        t(tokens.automator.resolutions.dialogs.send_email.aggregate_id_needs_to_be_selected_error)
      );
      setAggregateIdError(true);
      return;
    }

    const createRequest = (emailMessageDraft: EmailMessageDraft, aggregateId: number) => {
      return {
        order_item_id: emailMessageDraft.phase === ThreadPhase.ORDER ? aggregateId : undefined,
        shipment_id: emailMessageDraft.phase === ThreadPhase.SHIPMENT ? aggregateId : undefined,
        return_item_id: emailMessageDraft.phase === ThreadPhase.RETURN ? aggregateId : undefined,
        email_template_id: emailMessageDraft.messageTemplateId,
        subject: emailMessageDraft.subject,
        body: emailMessageDraft.body,
        recipient_type: emailMessageDraft.recipientType,
        phase: emailMessageDraft.phase,
        is_start_with_resolution_case: emailMessageDraft.startWithCase,
        files: emailMessageDraft.files,
      } as CreateEmailThreadData;
    };

    createEmailThreads(
      mode == StartEmailThreadMode.SINGLE
        ? [createRequest(emailMessageDraft, emailMessageDraft.aggregateId!)]
        : aggregateIds.map((aggregateId) => createRequest(emailMessageDraft, aggregateId)),
      {
        onSuccess: async () => {
          setEmailMessageDraft({
            ...draft,
            body: determineBody(RecipientType.CUSTOMER),
            subject: determineSubject(RecipientType.CUSTOMER),
            messageTemplateId: null,
            startWithCase: false,
          });
          alertId && dismissAlert({ alert_ids: [alertId] }, {});
          onClick();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email.email_sent));
        },
      }
    );
  };

  const onUpdateMessageTemplate = () => {
    updateMessageTemplate(
      {
        name: messageTemplate?.name || '',
        subject: draft?.subject || '',
        body: draft.body,
      },
      {
        onSuccess: async () => {
          toast.success(
            t(tokens.automator.resolutions.dialogs.update_email_template.email_template_updated)
          );
        },
      }
    );
  };

  return (
    <form
      noValidate
      autoComplete="off"
    >
      <Stack
        direction="row"
        gap={3}
      >
        <Stack
          direction="column"
          gap={1.5}
          width={!initialMessageTemplateId ? '70%' : '100%'}
        >
          {mode == StartEmailThreadMode.SINGLE && (
            <Box border={aggregateIdError ? 1 : 0}>
              <EmailThreadAggregateSelection
                canChangePhase={canChangePhase}
                onUpdatePhase={onUpdatePhase}
                orderId={orderId!}
                phase={draft.phase}
                aggregateId={draft.aggregateId}
                setAggregateId={(aggregateId) => {
                  setEmailMessageDraft({
                    ...draft,
                    aggregateId,
                  });
                  setAggregateId(aggregateId);
                  setAggregateIdError(false);
                }}
              />
            </Box>
          )}

          {canChangePhase && <hr />}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <RecipientTypeToggleButtonGroup
              onSelect={onUpdateRecipientType}
              value={draft.recipientType}
              phase={draft.phase}
            />

            {mode == StartEmailThreadMode.BULK && (
              <Chip
                label={
                  aggregateIds.length +
                  ' ' +
                  t(tokens.automator.resolutions.dialogs.send_email.amount_of_recipients)
                }
                color="success"
              />
            )}
          </Stack>

          <TextField
            id="subject-field"
            label={t(tokens.automator.resolutions.dialogs.send_email.subject)}
            variant="filled"
            fullWidth
            value={draft.subject}
            onChange={(e) => setEmailMessageDraft({ ...draft, subject: e.target.value })}
          />

          <HtmlEditor
            content={draft.body}
            onChange={(body) => {
              if (draft.body === body) {
                return;
              }
              setEmailMessageDraft({ ...draft, body });
            }}
            insertVariable={insertVariable}
          />

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={draft.startWithCase}
                  onChange={(event) =>
                    setEmailMessageDraft({
                      ...draft,
                      startWithCase: event.target.checked,
                    })
                  }
                  name="startWithCase"
                  color="primary"
                />
              }
              label={t(tokens.automator.resolutions.dialogs.send_email.start_with_case)}
            />
          </Box>

          {messageTemplate && messageTemplate!.documents.length > 0 ? (
            <EmailDocumentsList documents={messageTemplate!.documents} />
          ) : (
            <Box marginLeft={-1}>
              <FileUploadForm
                onChange={(fileUploads: FileUpload[]) =>
                  setEmailMessageDraft({
                    ...draft,
                    files: fileUploads,
                  })
                }
                multiple
              />
            </Box>
          )}

          <Stack
            direction="row"
            gap={2}
          >
            <ActionButton
              icon={<Email />}
              label={t(tokens.automator.resolutions.dialogs.send_email.send_email)}
              onClick={() => onCreate(draft)}
              isLoading={isLoading}
              variant="contained"
              color="primary"
            />

            {initialMessageTemplateId && (
              <ActionButton
                icon={<Email />}
                label={t(
                  tokens.automator.resolutions.dialogs.update_email_template.update_email_template
                )}
                onClick={() => onUpdateMessageTemplate()}
                isLoading={isLoadingUpdateMessageTemplate}
                variant="outlined"
                color="primary"
              />
            )}
          </Stack>
        </Stack>

        {!initialMessageTemplateId && (
          <Box width="30%">
            <TemplateVariableSelection
              phase={draft.phase}
              onSelectTemplate={(messageTemplateId) => {
                setMessageTemplateId(messageTemplateId);
              }}
              selectedTemplateId={draft.messageTemplateId}
              onSelectVariable={setInsertVariable}
            />
          </Box>
        )}
      </Stack>
    </form>
  );
};
