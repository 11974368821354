import { RETURN_ITEMS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { useUpdateData } from '../../useUpdateData';
import { useReturnsQueryKeysUpdater } from './useReturnsQueryKeysUpdater';
import Returns, { AGGREGATE_KEY } from '../../../domain/automator/returns/Returns';
import { OrderReturnsQueryKey } from '../../queryKeys/OrderReturnsQueryKey';

export const useUpdateReturnItemOverwriteCustomerComments = (returnItemId: number) => {
  const url = useAutomatorApiAccountUrl(
    RETURN_ITEMS_PATH + '/' + returnItemId + `/update-overwrite-customer-comments`
  );

  const updater = useReturnsQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (returns: Returns) => {
    updateData(returns);
  };

  return useAuthorizedPost<Returns, UpdateReturnItemOverwriteCustomerCommentsData>(
    url,
    [OrderReturnsQueryKey()],
    undefined,
    onSuccess
  );
};

export interface UpdateReturnItemOverwriteCustomerCommentsData {
  overwrite_customer_comments: string;
}
