import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Store';
import { EmailMessageDraft, updateEmailMessageDraft } from './emailMessageDraftSlice';
import { ThreadPhase } from '../../domain/automator/messages/ThreadPhase';
import { RecipientType } from '../../domain/automator/messages/RecipientType';

export const useGetEmailMessageDraft = (
  aggregateId: number | null,
  emailPhase: ThreadPhase | null,
  recipientType: RecipientType
) => {
  const dispatch = useDispatch();
  const emailMessageDraft = useSelector((state: RootState) =>
    state.email_message_drafts.emailMessageDrafts.find((draft) => {
      return (
        draft.aggregateId === aggregateId &&
        draft.phase == emailPhase &&
        draft.recipientType === recipientType
      );
    })
  );

  const setEmailMessageDraft = (emailMessageDraft: EmailMessageDraft) => {
    dispatch(updateEmailMessageDraft(emailMessageDraft));
  };

  return { emailMessageDraft, setEmailMessageDraft };
};
