import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { Scrollbar } from '../../../../../devias/src/components/scrollbar';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { TableLoading } from '../../../../components/TableLoading';
import { TableEmpty } from '../../../../components/TableEmpty';
import Alert from '../../../../domain/automator/alerts/Alert';
import { AlertsListTableRows } from './AlertsListTableRows';
import { CollapsableAlertsListTableRows } from './CollapsableAlertsListTableRows';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { TableListDivider } from '../../returns/components/TableListDivider';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';

interface AlertsListTableProps {
  isLoading: boolean;
  alerts?: Alert[] | null;
}

export const AlertsListTable = ({ alerts, isLoading }: AlertsListTableProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <TableLoading message={t(tokens.common.loading)} />;
  }

  if (alerts!.length == 0) {
    return <TableEmpty message={t(tokens.common.no_items)} />;
  }

  const groupedAlertsByDate = alerts!
    .sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime())
    .reduce(
      (groups, alert) => {
        const date = new Date(alert.createdDateTime);
        date.setHours(0, 0, 0, 0);
        const formatedDate = getAlphabeticFormatFromDate(date);

        if (!groups[formatedDate]) {
          groups[formatedDate] = [];
        }
        groups[formatedDate]!.push(alert);

        return groups;
      },
      {} as { [key: string]: Alert[] }
    );

  const groupedAlertsByEventTrigger = (alerts: Alert[]) =>
    alerts!.reduce(
      (groups, alert) => {
        const trigger = alert.eventRule.trigger;
        if (!groups[trigger]) {
          groups[trigger] = [];
        }
        groups[trigger]!.push(alert);
        return groups;
      },
      {} as { [key: string]: Alert[] }
    );

  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={350}>{t(tokens.automator.alerts.trigger)}</TableCell>
              <TableCell>{t(tokens.automator.alerts.order)}</TableCell>
              <TableCell />
              <TableCell align="right">{t(tokens.automator.alerts.actions)}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(groupedAlertsByDate!).map(([date, alerts]) => {
              return (
                <>
                  <TableListDivider label={date} />
                  {Object.entries(groupedAlertsByEventTrigger(alerts)).map(
                    ([trigger, alertList], index2) => {
                      return alertList.length > 10 || trigger == EventTrigger.ORDER_OVERDUE ? (
                        <CollapsableAlertsListTableRows
                          key={index2}
                          eventTrigger={trigger as EventTrigger}
                          alerts={alertList}
                        />
                      ) : (
                        <AlertsListTableRows
                          key={trigger}
                          alerts={alertList}
                        />
                      );
                    }
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
