import { RESOLUTION_DOSSIERS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import ResolutionDossiers from '../../../domain/automator/resolutionDossiers/ResolutionDossiers';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';

export const useFetchResolutionDossiers = (status: ResolutionDossierStatus, page?: number) => {
  const url = useAutomatorApiAccountUrl(
    RESOLUTION_DOSSIERS_PATH + '?status=' + status + (page ? '&page=' + page : '')
  );
  return useFetchData<ResolutionDossiers>(url, ResolutionDossiersQueryKey(status, page));
};
