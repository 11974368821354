import Stack from '@mui/material/Stack';
import { ShipmentDetailsAddress } from '../../orders/components/ShipmentDetailsAddress';
import ReducedOrder from '../../../../domain/automator/orders/ReducedOrder';
import { tokens } from '../../../../locales/translationTokens';
import CopyEmailAddressButton from '../../orders/components/copyButtons/CopyEmailAddressButton';
import CopyPhoneNumberButton from '../../orders/components/copyButtons/CopyPhoneNumberButton';
import { CondensedOrderView } from './CondensedOrderView';
import { HouseSharp } from '@mui/icons-material';
import { OrderSummaryCardItem } from './OrderSummaryCardItem';

interface OrderSummaryOrderViewProps {
  order: ReducedOrder;
}

export const OrderSummaryOrderCardItem = ({ order }: OrderSummaryOrderViewProps) => {
  return (
    <OrderSummaryCardItem label={tokens.automator.order_summary.order}>
      <Stack
        direction="column"
        gap={1}
        alignItems="top"
        justifyContent="space-between"
      >
        <CondensedOrderView order={order} />

        <Stack
          direction="row"
          gap={3}
        >
          <HouseSharp
            sx={{
              fontSize: 70,
            }}
          />

          <Stack
            direction={'row'}
            gap={2}
            alignItems={'center'}
          >
            <ShipmentDetailsAddress
              pickupPoint={order.pickupPoint}
              shipmentDetails={order.shipmentDetails}
            />
            <Stack
              direction="row"
              gap={0.5}
            >
              <CopyEmailAddressButton
                aggregateId={order.id}
                emailAddress={order.shipmentDetails.email}
              />

              {order.shipmentDetails.deliveryPhoneNumber && (
                <CopyPhoneNumberButton
                  aggregateId={order.id}
                  phoneNumber={order.shipmentDetails.deliveryPhoneNumber}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </OrderSummaryCardItem>
  );
};
