import { ActionIcon } from './ActionIcon';
import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { ActionType } from './copyButtons/ActionType';
import ActionButton from '../../../../components/ActionButton';
import { EmailOutlined } from '@mui/icons-material';
import { tokens } from '../../../../locales/translationTokens';
import { colors } from '@mui/material';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';

interface OpenStartOrderItemEmailThreadDialogButtonProps {
  orderId: number;
  orderItemId: number;
  body: string | null;
  type: ActionType;
}

export const OpenStartOrderItemEmailThreadDialogButton = ({
  orderId,
  orderItemId,
  body,
  type,
}: OpenStartOrderItemEmailThreadDialogButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const icon = <EmailOutlined style={{ color: colors.blue[400] }} />;
  const label = t(tokens.automator.returns.dialogs.send_email.send_email);

  const onClick = () => {
    openDialog({
      orderId: orderId,
      messageTemplateId: null,
      aggregateId: orderItemId,
      phase: ThreadPhase.ORDER,
      body: body,
      recipientType: RecipientType.CUSTOMER,
    });
  };
  return type === ActionType.BUTTON ? (
    <ActionButton
      size="small"
      icon={icon}
      label={t(label)}
      onClick={onClick}
    />
  ) : (
    <ActionIcon
      icon={icon}
      tooltip={t(label)}
      onClick={onClick}
    />
  );
};
