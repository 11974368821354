import { QueryKeysUpdater } from '../../useUpdateData';
import ResolutionDossier from '../../../domain/automator/resolutionDossiers/ResolutionDossier';
import { ResolutionDossiersQueryKey } from './ResolutionDossiersQueryKey';
import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';

export const useResolutionDossierQueryKeysUpdater = (): QueryKeysUpdater<ResolutionDossier> => {
  return {
    [ResolutionDossiersQueryKey(ResolutionDossierStatus.HANDLED)]: dossierUpdater(
      ResolutionDossierStatus.HANDLED
    ),
    [ResolutionDossiersQueryKey(ResolutionDossierStatus.OPEN)]: dossierUpdater(
      ResolutionDossierStatus.OPEN
    ),
    [ResolutionDossiersQueryKey(ResolutionDossierStatus.AWAITING_RESPONSE)]: dossierUpdater(
      ResolutionDossierStatus.AWAITING_RESPONSE
    ),
  };
};

const dossierUpdater = (status: ResolutionDossierStatus) => {
  return {
    addCriteria: (data: ResolutionDossier) => {
      return data.status === status;
    },
  };
};
