import { useTranslation } from 'react-i18next';
import Alert from '../../../../domain/automator/alerts/Alert';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { EventRuleActionType } from '../../../../domain/automator/eventRules/EventRuleActionType';
import { tokens } from '../../../../locales/translationTokens';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EventRuleAction from '../../../../domain/automator/eventRules/EventRuleAction';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { Box } from '@mui/system';
import ActionButton from '../../../../components/ActionButton';
import { DismissAlertsActionIcon } from './DismissAlertsActionIcon';

interface SuggestedActionProps {
  alert: Alert;
}

export const SuggestedAction = ({ alert }: SuggestedActionProps) => {
  const { t } = useTranslation();

  const openCreateEmailThreadDialog = useOpenDialog(DialogId.CREATE_EMAIL_THREAD);

  const openCreateResendDialog = useOpenDialog(DialogId.CREATE_RESEND);

  const determineActionName = (eventRuleAction: EventRuleAction) => {
    switch (eventRuleAction.type) {
      case EventRuleActionType.SEND_EMAIL:
        return eventRuleAction.emailAction?.recipientType == RecipientType.CUSTOMER
          ? t(tokens.automator.alerts.event_rule_action_type.send_customer_email)
          : t(tokens.automator.alerts.event_rule_action_type.send_request_refund_email);
      case EventRuleActionType.CREATE_RESEND:
        return t(tokens.automator.alerts.event_rule_action_type.create_resend);
      case EventRuleActionType.MONITOR_RETURN_TO_SENDER:
        return t(tokens.automator.alerts.event_rule_action_type.monitor_return_to_sender);
    }
  };

  const onExecute = () => {
    switch (alert.eventRuleAction.type) {
      case EventRuleActionType.SEND_EMAIL: {
        const determineAggregateId = (alert: Alert) => {
          switch (alert.eventRule.phase) {
            case 'ORDER':
              return alert.orderItemId
                ? alert.orderItemId
                : alert.order.items.length == 1
                ? alert.order.items[0].id
                : null;
            case 'SHIPMENT':
              return alert.shipmentId;
            case 'RETURN':
              return alert.returnItemId ? alert.returnItemId : null;
          }
        };

        openCreateEmailThreadDialog({
          alertId: alert.id,
          aggregateId: determineAggregateId(alert) ?? null,
          orderId: alert.order.id,
          phase: alert.eventRule.phase,
          messageTemplateId: alert.eventRuleAction.emailAction!.messageTemplate.id,
          recipientType: alert.eventRuleAction.emailAction!.recipientType,
          body: null,
        });
        break;
      }
      case EventRuleActionType.CREATE_RESEND:
        openCreateResendDialog({
          orderId: alert.order.id,
          alertId: alert.id,
        });
        break;
      case EventRuleActionType.MONITOR_RETURN_TO_SENDER:
        break;
    }
  };

  return (
    <Stack
      gap={3}
      direction="row"
      alignItems={'center'}
    >
      <Box width={220}>
        <Chip
          style={{ borderRadius: 4, fontSize: 15 }}
          label={determineActionName(alert.eventRuleAction)}
          variant="outlined"
        />
      </Box>

      <ActionButton
        icon={<PlayCircleOutlineIcon />}
        label={t(tokens.automator.alerts.execute)}
        onClick={onExecute}
        size={'small'}
        color={'success'}
      />

      <DismissAlertsActionIcon alertIds={[alert.id]} />
    </Stack>
  );
};
