import Alert from '../../../../domain/automator/alerts/Alert';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { AlertsListTableRows } from './AlertsListTableRows';
import { AlertsListTableEventTriggerGroupRow } from './AlertsListTableEventTriggerGroupRow';
import { useState } from 'react';

interface CollapsableAlertsListTableRowsProps {
  eventTrigger: EventTrigger;
  alerts: Alert[];
}

export const CollapsableAlertsListTableRows = ({
  alerts,
  eventTrigger,
}: CollapsableAlertsListTableRowsProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  return (
    <>
      <AlertsListTableEventTriggerGroupRow
        onClick={() => setIsHidden(!isHidden)}
        alerts={alerts}
        eventTrigger={eventTrigger}
      />

      {isHidden ? <></> : <AlertsListTableRows alerts={alerts} />}
    </>
  );
};
