import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { useFetchOrderMessageThreads } from '../../../../api/automator/emails/useFetchOrderMessageThreads';
import Stack from '@mui/material/Stack';
import { InitialEmailMessageOpenedIndicator } from './InitialEmailMessageOpenedIndicator';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TriggerTypeChip from '../../resolutions/components/TriggerTypeChip';

interface OrderSummaryMessageThreadsProps {
  orderId: number;
}

export const OrderSummaryMessageThreads = ({ orderId }: OrderSummaryMessageThreadsProps) => {
  const { data: messageThreads, isLoading } = useFetchOrderMessageThreads(orderId);

  //const { t } = useTranslation();

  if (isLoading) {
    return <>...loading</>;
  }

  const sortedMessageThreads = messageThreads?.sort((a, b) => {
    return new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime();
  });

  return (
    <Table>
      {sortedMessageThreads?.map((messageThread) => (
        <TableRow key={messageThread.id}>
          <SlimTableCell>
            <Typography
              noWrap
              variant="body2"
              color="textSecondary"
            >
              {messageThread.createdDateTime}
            </Typography>
          </SlimTableCell>
          <SlimTableCell>
            <Stack
              direction="column"
              gap={0.5}
            >
              <Box>
                <TriggerTypeChip triggerType={messageThread.triggerType} />
              </Box>
              <Typography noWrap>{messageThread.subject}</Typography>
            </Stack>
          </SlimTableCell>
          <SlimTableCell>
            <Box>
              {messageThread.initialMessageOpenedDateTime && (
                <InitialEmailMessageOpenedIndicator
                  initialEmailMessageOpenendDateTime={messageThread.initialMessageOpenedDateTime}
                />
              )}
            </Box>
          </SlimTableCell>
        </TableRow>
      ))}
    </Table>
  );
};
