import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Resend from '../../../../domain/automator/resends/Resend';
import { useHandleResend } from '../../../../api/automator/resolutions/useHandleResend';
import { OrderRecipient } from '../../orders/components/OrderRecipient';
import { ShipmentDetailsAddress } from '../../orders/components/ShipmentDetailsAddress';
import ActionButton from '../../../../components/ActionButton';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import Typography from '@mui/material/Typography';
import RetailerLooksIcon from '../../retailers/components/RetailerLooksIcon';
import { ResendStatus } from '../../../../domain/automator/resends/ResendStatus';
import OrderNoteTypography from '../../orders/components/OrderNoteTypography';
import { OpenOrderSummaryActionIcon } from '../../orderSummaries/components/OpenOrderSummaryActionIcon';
import { OrderItemProductInfo } from '../../orders/components/OrderItemProductInfo';

interface ResendTableRowProps {
  resend: Resend;
}

export const ResendTableRow = ({ resend }: ResendTableRowProps) => {
  const { t } = useTranslation();

  const { mutate: handleResend } = useHandleResend(resend.id);

  const onHandleResend = () => {
    handleResend(undefined, {
      onSuccess: () => {
        toast.success(t(tokens.automator.resolutions.actions.resend_handled));
      },
    });
  };

  return (
    <TableRow>
      <TableCell>
        <Stack direction="column">
          {resend.order.items.map((orderItem) => (
            <OrderItemProductInfo
              key={orderItem.id}
              orderStatus={resend.order.status}
              orderItemStatus={orderItem.status}
              quantity={orderItem.quantity}
              offer={orderItem.offer}
            />
          ))}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <Typography variant={'body1'}>{resend.order.bolOrderId}</Typography>
          <RetailerLooksIcon retailerId={resend.order.retailerId} />
          <OpenOrderSummaryActionIcon bolOrderId={resend.order.bolOrderId} />
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          gap={'0.1rem'}
        >
          <OrderRecipient
            shipmentDetails={resend.order.shipmentDetails}
            isRepeatCustomer={false}
          />
          <ShipmentDetailsAddress
            pickupPoint={false}
            shipmentDetails={resend.order.shipmentDetails}
          />
        </Stack>
      </TableCell>

      <TableCell>
        <OrderNoteTypography orderId={resend.order.id} />
      </TableCell>

      <TableCell>
        {resend.status == ResendStatus.OPEN && (
          <ActionButton
            size="small"
            label={t(tokens.automator.resolutions.actions.handle_resend)}
            onClick={onHandleResend}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
