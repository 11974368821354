import OrderItem from '../../../../domain/automator/orders/OrderItem';
import { OrderItemProductInfo } from './OrderItemProductInfo';
import { CancelOrderItemButton } from './CancelOrderItemButton';
import { OpenStartOrderItemEmailThreadDialogButton } from './OpenStartOrderItemEmailThreadDialogButton';
import Stack from '@mui/material/Stack';
import { HasOpenResolutionCaseIndicator } from '../../resolutions/components/HasOpenResolutionCaseIndicator';
import Order from '../../../../domain/automator/orders/Order';
import { OpenOrderSummaryActionIcon } from '../../orderSummaries/components/OpenOrderSummaryActionIcon';
import Checkbox from '@mui/material/Checkbox';
import { OrdersMode } from '../OrdersPage';
import { ActionType } from './copyButtons/ActionType';
import { OpenUpdateOfferDialogButton } from './OpenUpdateOfferDialogButton';
import { CancellationRequestChip } from './CancellationRequestChip';

interface OrderItemCardProps {
  order: Order;
  orderItem: OrderItem;
  mode: OrdersMode;
  isSelected: boolean;
  onSelect: (orderItem: OrderItem) => void;
}

export const OrderItemCard = ({
  order,
  orderItem,
  mode,
  onSelect,
  isSelected,
}: OrderItemCardProps) => {
  return (
    <Stack
      gap={5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {mode == OrdersMode.BULK_ORDER_ITEMS && (
        <Checkbox
          checked={isSelected}
          onChange={() => onSelect(orderItem)}
        />
      )}

      <OrderItemProductInfo
        orderStatus={order.status}
        orderItemStatus={orderItem.status}
        quantity={orderItem.quantity}
        offer={orderItem.offer}
      />

      <Stack
        direction="column"
        gap={0.5}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={0.25}
        >
          <OpenOrderSummaryActionIcon bolOrderId={order.bolOrderId} />

          {orderItem.hasOpenCase && <HasOpenResolutionCaseIndicator />}

          <OpenStartOrderItemEmailThreadDialogButton
            orderId={order.id}
            body={null}
            orderItemId={orderItem.id}
            type={ActionType.ICON}
          />

          <OpenUpdateOfferDialogButton productId={orderItem.offer.product.id} />

          {mode == OrdersMode.BULK_ORDERS && (
            <CancelOrderItemButton
              onClick={() => onSelect(orderItem)}
              orderItem={orderItem}
            />
          )}
        </Stack>
        {orderItem.cancellationRequest && <CancellationRequestChip />}
      </Stack>
    </Stack>
  );
};
