import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import { ChipLabelColor } from '../../shared/ChipLabelColor';

interface ReturnItemStatusChipProps {
  returnItem: ReturnItem;
}

export const ReturnItemStatusChip = ({ returnItem }: ReturnItemStatusChipProps) => {
  const { t } = useTranslation();

  const determineLabelColor = (returnItem: ReturnItem): ChipLabelColor => {
    const status = returnItem.status;

    switch (status) {
      case ReturnItemStatus.REGISTERED:
        return returnItem.isHandled
          ? { label: tokens.automator.returns.status.handled, color: 'success' }
          : { label: tokens.automator.returns.status.new, color: 'primary' };
      case ReturnItemStatus.ON_HOLD:
        return returnItem.isHandled
          ? { label: tokens.automator.returns.status.handled, color: 'success' }
          : { label: tokens.automator.returns.status.on_hold, color: 'warning' };
      case ReturnItemStatus.REPAIR:
        return returnItem.isScanned
          ? { label: tokens.automator.returns.status.awaiting_repair, color: 'info' }
          : { label: tokens.automator.returns.status.repaired, color: 'success' };
      case ReturnItemStatus.EXCHANGE:
        return returnItem.isScanned
          ? { label: tokens.automator.returns.status.awaiting_exchange, color: 'info' }
          : { label: tokens.automator.returns.status.exchanged, color: 'success' };
    }
  };

  const chipLabelColor = determineLabelColor(returnItem);

  return (
    <Chip
      style={{ width: 120 }}
      color={chipLabelColor.color as any}
      label={t(chipLabelColor.label)}
    />
  );
};
