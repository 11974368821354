export const AUTOMATOR_API_URL = import.meta.env.VITE_AUTOMATOR_API_URL;

export const ACCOUNTS_PATH = 'accounts';
export const ORDERS_PATH = 'orders';
export const ORDER_SUMMARIES_PATH = 'order-summaries';

export const EVENT_RULES_PATH = 'event-rules';
export const EVENT_RULE_ACTIONS_PATH = 'event-rule-actions';
export const ALERTS_PATH = 'alerts';

export const MESSAGE_TEMPLATES_PATH = 'message-templates';
export const EMAIL_THREADS_PATH = 'email-threads';
export const MESSAGE_THREADS_PATH = 'message-threads';

export const RESOLUTION_DOSSIERS_PATH = 'resolution-dossiers';
export const RESOLUTION_CASES_PATH = 'resolution-cases';

export const ORDER_NOTES_PATH = 'order-notes';

export const OFFERS_PATH = 'offers';

export const RESENDS_PATH = 'resends';

export const CUSTOMER_ORDERS = '/customers/orders';

export const RETURN_PROCESSING_RESULTS_PATH = 'return-processing-results';
export const RETURN_ITEMS_PATH = 'return-items';
export const RETURNS_PATH = 'returns';
export const RETURN_SCANS_PATH = 'return-scans';

export const ORDER_ITEMS_PATH = 'order-items';
export const PICK_SESSION_PATH = 'pick-sessions';
export const PICK_ORDERS_PATH = 'pick-orders';

export const LABEL_TYPE_RULES_PATH = 'label-type-rules';
export const RETAILERS_PATH = 'retailers';
export const SHIPMENTS_PATH = 'shipments';

export const SETTINGS_PATH = 'settings';

export const RETURN_REASON_REPORTS_PATH = 'return-reports/reasons';

export const PRODUCTS_PATH = 'products';
export const PRODUCT_GROUPS_PATH = 'product-groups';
