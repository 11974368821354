import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ReducedResolutionCase from '../../../../domain/automator/resolutionDossiers/ReducedResolutionCase';
import { InitialEmailMessageOpenedIndicator } from '../../orderSummaries/components/InitialEmailMessageOpenedIndicator';
import RecipientTypeChip from './RecipientTypeChip';
import { PhaseChip } from '../../autoEmail/components/PhaseChip';
import { useTranslation } from 'react-i18next';
import { TriggerType } from '../../../../domain/automator/messages/TriggerType';
import Chip from '@mui/material/Chip';
import { tokens } from '../../../../locales/translationTokens';
import Colors from '../../../../colors/Colors';
import { GeneralResolutionCaseActions } from './GeneralResolutionCaseActions';

interface ResolutionCaseSelectProps {
  dossierHasMultipleCases: boolean;
  reducedResolutionCase: ReducedResolutionCase;
  isSelected: boolean;
  setSelectedCaseId: (resolutionCaseId: number) => void;
}

export const ResolutionCaseSelect = ({
  reducedResolutionCase,
  isSelected,
  setSelectedCaseId,
  dossierHasMultipleCases,
}: ResolutionCaseSelectProps) => {
  const { t } = useTranslation();

  const convertMinutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours >= 24) {
      return `24:00+`;
    } else {
      return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    }
  };

  return (
    <Box
      key={reducedResolutionCase.id}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: isSelected && dossierHasMultipleCases ? '1px dashed #000' : 'none',
      }}
      borderRadius={1}
      alignItems="center"
      paddingX={2}
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        onClick={() => setSelectedCaseId(reducedResolutionCase.id)}
      >
        <Box minWidth={150}>
          {reducedResolutionCase.triggerType == TriggerType.CUSTOMER_QUESTION && (
            <Stack
              direction="row"
              gap={0.5}
              alignItems="center"
            >
              <Chip
                size="small"
                label={t(tokens.automator.resolutions.customer_question)}
                color="primary"
                style={{
                  borderRadius: 4,
                  backgroundColor: 'WHITE',
                  border: `1px solid ${Colors.PRIMARY_BOL_BLUE}`,
                  color: Colors.PRIMARY_BOL_BLUE,
                }}
              />
              {reducedResolutionCase.minutesAfterMessageRequiringReply && (
                <Chip
                  size="small"
                  label={convertMinutesToTime(
                    reducedResolutionCase.minutesAfterMessageRequiringReply
                  )}
                  color="primary"
                  style={{
                    borderRadius: 4,
                    backgroundColor: Colors.PRIMARY_BOL_BLUE,
                    color: 'WHITE',
                  }}
                />
              )}
            </Stack>
          )}
        </Box>

        <Box width={30}>
          {reducedResolutionCase.initialEmailMessageOpenedDateTime && (
            <InitialEmailMessageOpenedIndicator
              initialEmailMessageOpenendDateTime={
                reducedResolutionCase.initialEmailMessageOpenedDateTime
              }
            />
          )}
        </Box>

        <Stack
          direction="row"
          gap={0.5}
          alignItems="center"
          minWidth={200}
        >
          <RecipientTypeChip recipientType={reducedResolutionCase.recipientType} />
          <PhaseChip phase={reducedResolutionCase.phase} />
        </Stack>

        <Typography variant="body1">{reducedResolutionCase.subject}</Typography>
      </Stack>

      <GeneralResolutionCaseActions
        key={reducedResolutionCase.id}
        resolutionCase={reducedResolutionCase}
      />
    </Box>
  );
};
