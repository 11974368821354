import { tokens } from '../../../../locales/translationTokens';
import { Ballot, BallotOutlined } from '@mui/icons-material';
import { ActionIcon } from '../../orders/components/ActionIcon';
import { useTranslation } from 'react-i18next';
import ReducedResolutionCase from '../../../../domain/automator/resolutionDossiers/ReducedResolutionCase';

interface HasOpenResolutionCaseIndicatorProps {
  reducedResolutionCase?: ReducedResolutionCase;
}

export const HasOpenResolutionCaseIndicator = ({
  reducedResolutionCase,
}: HasOpenResolutionCaseIndicatorProps) => {
  const { t } = useTranslation();

  return (
    <ActionIcon
      icon={
        reducedResolutionCase?.amountOfUnreadMessageThreadReplies == 0 ? (
          <BallotOutlined />
        ) : (
          <Ballot />
        )
      }
      tooltip={t(tokens.automator.resolutions.indicator.has_case)}
    />
  );
};
