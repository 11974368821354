import { OrderListTable } from './components/OrderListTable';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../locales/translationTokens';
import { OrderStatus } from '../../../domain/automator/orders/OrderStatus';
import { useEffect, useState } from 'react';
import AccountPage from '../../../components/AccountPage';
import useQueryParam from '../../../helpers/useQueryParam';
import { useFetchAccount } from '../../../api/automator/account/useFetchAccount';
import { SuccessAlert } from '../../../components/alerts/SuccessAlert';
import { WarningAlert } from '../../../components/alerts/WarningAlert';
import { ErrorAlert } from '../../../components/alerts/ErrorAlert';
import { OrdersTutorial } from '../../../components/tutorials/OrdersTutorial';
import ApprovePendingCancellationRequestsButton from './components/ApprovePendingCancellationRequestsButton';
import OrderTabs from './components/OrderTabs';
import { useFetchOrders } from '../../../api/automator/orders/useFetchOrders';
import ApplicationError from '../../../domain/automator/ApplicationError';
import { DistributionParty } from '../../../domain/automator/orders/DistributionParty';
import { CountryCode } from '../../../domain/automator/orders/CountryCode';
import OrdersCountrySelect from './components/OrdersCountrySelect';
import OrdersPerDayTable from './components/OrdersPerDayTable';
import { useOrderTab } from '../../../store/orderTabs/useOrderTab';
import ActionButton from '../../../components/ActionButton';

interface OrdersPageProps {
  distributionParty: DistributionParty;
}

export enum OrdersMode {
  BULK_ORDERS = 'BULK_ORDERS',
  BULK_ORDER_ITEMS = 'BULK_ORDER_ITEMS',
  VIEW = 'VIEW',
}

const OrdersPage = ({ distributionParty }: OrdersPageProps) => {
  const { setOrderTab, orderTab: currentTab } = useOrderTab();

  const [countries, setCountries] = useState([CountryCode.BE, CountryCode.NL]);

  const determineMode = (currentTab: OrderStatus) => {
    if (currentTab == OrderStatus.OPEN || currentTab == OrderStatus.FAILED) {
      return OrdersMode.BULK_ORDERS;
    } else {
      return OrdersMode.VIEW;
    }
  };

  const [mode, setMode] = useState<OrdersMode>(determineMode(currentTab));

  const [selectedStartDate, setSelectedStartDate] = useState<string | undefined>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(undefined);

  const {
    data: orders,
    isError,
    error,
    isLoading,
  } = useFetchOrders(currentTab, distributionParty, selectedStartDate, selectedEndDate);

  const { data: account } = useFetchAccount();

  const { t } = useTranslation();

  const paymentSuccess = useQueryParam('payment_successful');

  const paymentFailed = useQueryParam('payment_failed');

  const onClickCountry = (country: CountryCode) => {
    setCountries((currentCountries) => {
      if (currentCountries.includes(country)) {
        return currentCountries.filter((c) => c !== country);
      }
      return [...currentCountries, country];
    });
  };

  useEffect(() => {
    setOrderTab({ orderTab: OrderStatus.OPEN });
  }, [distributionParty]);

  useEffect(() => {
    setSelectedEndDate(undefined);
    setSelectedStartDate(undefined);
  }, [currentTab]);

  const buttons = [];
  const hasPendingCancellationRequests = isLoading
    ? false
    : orders!.report!.totalCancellationRequests > 0;
  if (hasPendingCancellationRequests) {
    buttons.push(
      <ApprovePendingCancellationRequestsButton
        key={1}
        totalCancellationRequests={orders!.report.totalCancellationRequests}
      />
    );
  }

  if (mode != OrdersMode.BULK_ORDER_ITEMS) {
    buttons.push(
      <ActionButton
        key={2}
        label={t(tokens.automator.orders.bulk_mail)}
        onClick={() => {
          setMode(OrdersMode.BULK_ORDER_ITEMS);
        }}
      />
    );
  } else {
    buttons.push(
      <ActionButton
        key={3}
        label={t(tokens.automator.orders.abort_bulk_mail)}
        onClick={() => {
          setMode(determineMode(currentTab));
        }}
        color="warning"
      />
    );
  }

  const titleExtra = (
    <OrdersCountrySelect
      orders={orders}
      onClickCountry={onClickCountry}
      countries={countries}
    />
  );

  const alerts = [];
  account?.settings.forceDoNotIgnoreLatestHandoverDate &&
    alerts.push(
      <WarningAlert>
        {t(tokens.automator.orders.force_do_not_ignore_latest_handover_date_explanation)}
      </WarningAlert>
    );

  if (paymentSuccess) {
    alerts.push(<SuccessAlert>{t(tokens.automator.pricing.payment_successful)}</SuccessAlert>);
  }

  if (paymentFailed) {
    alerts.push(<ErrorAlert>{t(tokens.automator.pricing.payment_failed)}</ErrorAlert>);
  }

  return (
    <AccountPage
      title={tokens.automator.orders.orders}
      titleExtra={titleExtra}
      alerts={alerts}
      buttons={buttons}
      tutorialAlert={<OrdersTutorial />}
    >
      <OrderTabs
        ordersReport={orders?.report}
        distributionParty={distributionParty}
      />
      {currentTab === OrderStatus.SHIPPED ? (
        <OrdersPerDayTable
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          setSelectedStartDate={setSelectedStartDate}
          orders={orders}
          account={account!}
          distributionParty={distributionParty}
          isLoading={isLoading}
          selectedCountries={countries}
          mode={mode}
        />
      ) : (
        <OrderListTable
          distributionParty={distributionParty}
          orders={orders?.orders}
          top3TransporterCodes={orders?.report?.top3TransporterCodes}
          isError={isError}
          isLoading={isLoading}
          error={error! as unknown as ApplicationError}
          selectedCountries={countries}
          mode={mode}
        />
      )}
    </AccountPage>
  );
};

export default OrdersPage;
