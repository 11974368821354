import Chip from '@mui/material/Chip';
import { TriggerType } from '../../../../domain/automator/messages/TriggerType';

interface TriggerTypeChipProps {
  triggerType: TriggerType;
}

const TriggerTypeChip = ({ triggerType }: TriggerTypeChipProps) => {
  return (
    <Chip
      size="small"
      label={triggerType}
      color="info"
      variant="outlined"
    />
  );
};

export default TriggerTypeChip;
