import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '../../../../domain/automator/alerts/Alert';
import { CondensedOrderView } from '../../orderSummaries/components/CondensedOrderView';
import { AlertEventTriggerInfo } from './AlertEventTriggerInfo';
import { AlertActions } from './AlertActions';

interface AlertsListTableRowProps {
  alerts: Alert[];
}

export const AlertsListTableRow = ({ alerts }: AlertsListTableRowProps) => {
  const firstAlert = alerts[0];
  const order = firstAlert.order;

  return (
    <TableRow>
      <TableCell width={350}>
        <AlertEventTriggerInfo
          eventTrigger={firstAlert.eventRule.trigger}
          distributionParty={firstAlert.eventRule.distributionParty}
        />
      </TableCell>

      <TableCell>
        <CondensedOrderView order={order} />
      </TableCell>

      <TableCell></TableCell>

      <TableCell>
        <AlertActions alerts={alerts} />
      </TableCell>
    </TableRow>
  );
};
