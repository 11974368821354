import * as React from 'react';
import Popover from '@mui/material/Popover';
import { AddCircle } from '@mui/icons-material';
import { ActionIcon } from '../../orders/components/ActionIcon';
import SpeedDialMenu from './SpeedDialMenu';
import { Box } from '@mui/system';
import { colors } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';

interface SpeedDialActionIconProps {
  orderId: number;
  orderItemId?: number;
  hasNote: boolean;
}

export default function SpeedDialActionIcon({
  orderId,
  orderItemId,
  hasNote,
}: SpeedDialActionIconProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'speed-dial' : undefined;

  return (
    <Box style={{ backgroundColor: 'transparent' }}>
      <Box sx={{ position: 'relative', display: 'inline-block', color: 'transparent' }}>
        <ActionIcon
          icon={<AddCircle />}
          tooltip={t(tokens.automator.speed_dial.speed_dial)}
          onClick={handleClick}
          color={'primary'}
          iconSize={30}
        />
        {hasNote && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 14,
              height: 14,
              bgcolor: colors.green[400],
              borderRadius: '50%',
              border: '1px solid white',
            }}
          />
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SpeedDialMenu
          orderId={orderId}
          orderItemId={orderItemId}
        />
      </Popover>
    </Box>
  );
}
