import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import Box from '@mui/material/Box';
import { EmailThreadOrderItemSelection } from './EmailThreadOrderItemSelection';
import { EmailThreadShipmentSelection } from './EmailThreadShipmentSelection';
import { EmailThreadReturnItemSelection } from './EmailThreadReturnItemSelection';
import { EmailPhaseToggleButtonGroup } from './EmailPhaseToggleButtonGroup';
import Stack from '@mui/material/Stack';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';
import { useFetchOrderReturns } from '../../../../api/automator/orders/useFetchOrderReturns';
import Return from '../../../../domain/automator/returns/Return';
import Shipment from '../../../../domain/automator/shipments/Shipment';

interface EmailThreadAggregateSelectionProps {
  orderId: number;
  aggregateId: number | null;
  setAggregateId: (aggregateId: number) => void;
  canChangePhase: boolean;
  phase: ThreadPhase;
  onUpdatePhase: (phase: ThreadPhase) => void;
}

export const EmailThreadAggregateSelection = ({
  orderId,
  phase,
  aggregateId,
  setAggregateId,
  canChangePhase,
  onUpdatePhase,
}: EmailThreadAggregateSelectionProps) => {
  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(orderId);

  const { data: shipment, isLoading: isLoadingOrderShipment } = useFetchOrderShipment(orderId);

  const { data: returns, isLoading: isLoadingOrderReturns } = useFetchOrderReturns(orderId);

  if (isLoadingOrder || isLoadingOrderShipment || isLoadingOrderReturns) {
    return <div>Loading...</div>;
  }

  const determineSelectablePhase = (returns: Return[], shipment?: Shipment | null) => {
    const selectablePhases = [ThreadPhase.ORDER];

    if (shipment) {
      selectablePhases.push(ThreadPhase.SHIPMENT);
    }

    if (returns.length > 0) {
      selectablePhases.push(ThreadPhase.RETURN);
    }

    return selectablePhases;
  };

  return (
    <Stack
      direction="column"
      gap={1.5}
    >
      {canChangePhase && (
        <EmailPhaseToggleButtonGroup
          onSelect={onUpdatePhase}
          value={phase}
          selectablePhases={determineSelectablePhase(returns!, shipment) || []}
        />
      )}

      <Box>
        {phase == ThreadPhase.ORDER && (
          <EmailThreadOrderItemSelection
            order={order!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {phase == ThreadPhase.SHIPMENT && (
          <EmailThreadShipmentSelection
            shipment={shipment!}
            setAggregateId={setAggregateId}
            selectedAggregateId={aggregateId}
          />
        )}

        {phase == ThreadPhase.RETURN && (
          <EmailThreadReturnItemSelection
            returns={returns!}
            selectedAggregateId={aggregateId}
            setAggregateId={setAggregateId}
          />
        )}
      </Box>
    </Stack>
  );
};
