import { tokens } from '../../../locales/translationTokens';
import { ProductListTable } from './components/ProductListTable';
import AccountPage from '../../../components/AccountPage';
import { LabelTypeRulesTutorial } from '../../../components/tutorials/LabelTypeRulesTutorial';
import { useFetchAutomatorProducts } from '../../../api/automator/products/useFetchAutomatorProducts';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import ActionButton from '../../../components/ActionButton';
import { useTranslation } from 'react-i18next';
import { useFetchProductGroups } from '../../../api/automator/products/useFetchProductGroups';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ProductGroupChip } from './components/ProductGroupChip';
import ProductsPageSearchBar from './components/ProductsPageSearchBar';
import AutomatorProduct from '../../../domain/automator/products/AutomatorProduct';

const ProductsPage = () => {
  const { data: loadedProducts, isLoading: isLoadingProducts } = useFetchAutomatorProducts();
  const { data: productGroups, isLoading: isLoadingProductGroups } = useFetchProductGroups();
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<AutomatorProduct[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const openCreateProductGroupDialog = useOpenDialog(DialogId.CREATE_PRODUCT_GROUP);
  const openUpdateProductsDialog = useOpenDialog(DialogId.UPDATE_PRODUCTS);

  const { t } = useTranslation();

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false); // Indicating the rendering is complete
    }
  }, [searchedProducts]);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setIsSearching(true);
      if (query === '') {
        setSearchedProducts(null);
      } else if (loadedProducts) {
        setSearchedProducts(
          loadedProducts.products.filter((product) =>
            product.title.toLowerCase().includes(query.toLowerCase())
          )
        );
      }
    }, 500),
    [loadedProducts]
  );

  const onSelectGroup = (productGroupId: number) => {
    const newGroupIds = selectedGroupIds.includes(productGroupId)
      ? selectedGroupIds.filter((id) => id !== productGroupId)
      : [...selectedGroupIds, productGroupId];
    setSelectedGroupIds(newGroupIds);

    setIsSearching(true);
    if (newGroupIds.length > 0) {
      setSearchedProducts(
        loadedProducts!.products.filter((product) =>
          product.productGroups.some((group) => newGroupIds.includes(group.id))
        )
      );
    } else {
      setSearchedProducts(null);
    }
  };

  const buttons = [
    <ActionButton
      key={2}
      label={t(tokens.automator.products.dialogs.create_group.create_group)}
      onClick={() => {
        openCreateProductGroupDialog();
      }}
      variant={productGroups?.productGroups?.length ?? 0 > 0 ? 'outlined' : 'contained'}
    />,
  ];

  if (productGroups?.productGroups?.length ?? 0 > 0) {
    buttons.unshift(
      <ActionButton
        key={1}
        label={t(tokens.automator.products.dialogs.update_products.update_products)}
        onClick={() => {
          loadedProducts &&
            openUpdateProductsDialog({
              products: loadedProducts?.products || [],
            });
        }}
        color="primary"
      />
    );
  }

  return (
    <AccountPage
      title={tokens.automator.products.products}
      tutorialAlert={<LabelTypeRulesTutorial />}
      titleExtra={
        <Stack
          direction="row"
          gap={3}
          alignItems="center"
        >
          <ProductsPageSearchBar
            isDisabled={isLoadingProducts}
            onSearch={debouncedSearch}
          />

          <Stack
            direction="row"
            gap={0.5}
          >
            {productGroups?.productGroups?.map((productGroup) => (
              <ProductGroupChip
                key={productGroup.id}
                productGroup={productGroup}
                onClick={() => onSelectGroup(productGroup.id)}
                isSelected={selectedGroupIds.includes(productGroup.id)}
              />
            ))}
          </Stack>
        </Stack>
      }
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.products.dialogs.update_products.update_products)}
          onClick={() => {
            loadedProducts &&
              openUpdateProductsDialog({
                products: loadedProducts?.products || [],
              });
          }}
          color="primary"
        />,

        <ActionButton
          key={2}
          label={t(tokens.automator.products.dialogs.create_group.create_group)}
          onClick={() => {
            openCreateProductGroupDialog();
          }}
          variant={productGroups?.productGroups?.length ?? 0 > 0 ? 'outlined' : 'contained'}
        />,
      ]}
    >
      <ProductListTable
        productGroups={productGroups?.productGroups}
        products={searchedProducts ? searchedProducts : loadedProducts?.products}
        isLoading={isLoadingProducts || isLoadingProductGroups || isSearching}
      />
    </AccountPage>
  );
};

export default ProductsPage;
