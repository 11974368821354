import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import colors from '../../../../colors/Colors';

interface SpeedDialMenuItemProps {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

export default function SpeedDialMenuItem({ icon, label, onClick }: SpeedDialMenuItemProps) {
  const { t } = useTranslation();

  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon style={{ color: colors.PRIMARY_BOL_BLUE }}>{icon}</ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItemButton>
  );
}
