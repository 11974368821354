import { QueryKeysUpdater } from '../../useUpdateData';
import Alert from '../../../domain/automator/alerts/Alert';
import { AlertsQueryKey } from '../../queryKeys/AlertsQueryKey';
import { AlertStatus } from '../../../domain/automator/alerts/AlertStatus';

export const useAlertQueryKeysUpdater = (): QueryKeysUpdater<Alert> => {
  return {
    [AlertsQueryKey(AlertStatus.AWAITING)]: alertUpdater(AlertStatus.AWAITING),
    [AlertsQueryKey(AlertStatus.DISMISSED)]: alertUpdater(AlertStatus.DISMISSED),
    [AlertsQueryKey(AlertStatus.ACTION_EXECUTED)]: alertUpdater(AlertStatus.ACTION_EXECUTED),
  };
};

const alertUpdater = (status: AlertStatus) => {
  return {
    addCriteria: (data: Alert) => {
      return data.status === status;
    },
  };
};
