import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { Email } from '@mui/icons-material';
import { useSendMessageThreadReply } from '../../../../api/automator/emails/useSendMessageThreadReply';
import { toast } from 'react-hot-toast';
import Stack from '@mui/material/Stack';
import HtmlEditor from '../../autoEmail/components/HtmlEditor';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';
import MessageThread from '../../../../domain/automator/messages/MessageThread';
import { useFetchMessageTemplate } from '../../../../api/automator/emails/useFetchMessageTemplate';

interface SendMessageThreadReplyHtmlEditorProps {
  onClick?: () => void;
  messageThread: MessageThread;
  messageTemplateId: number | null;
  insertVariable: string | null;
}

export const SendMessageThreadReplyHtmlEditor = ({
  onClick,
  messageThread,
  messageTemplateId,
  insertVariable,
}: SendMessageThreadReplyHtmlEditorProps) => {
  const { data: messageTemplate, isLoading: isLoadingMessageTemplate } =
    useFetchMessageTemplate(messageTemplateId);

  console.log('messageTemplate', messageTemplateId);

  const { mutate: sendReply, isLoading } = useSendMessageThreadReply(messageThread.id);

  useEffect(() => {
    if (messageTemplate !== null) {
      setBody(messageTemplate?.body || '');
    }
  }, [messageTemplate]);

  const determineBody = (recipientType: RecipientType) => {
    if (recipientType == RecipientType.CUSTOMER) {
      return `Beste [[CUSTOMER_NAME]],<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Partner van Bol.com`;
    }

    if (recipientType == RecipientType.BOL) {
      return `Beste Bol,<br><br><br><br>Met vriendelijke groet,<br><br>Team [[STORE_NAME]]<br>Winkelnummer: [[BOL_RETAILER_ID]]`;
    }
    return '';
  };

  const [body, setBody] = useState(
    messageTemplate?.body ? messageTemplate.body : determineBody(messageThread.recipientType)
  );

  console.log('b', body);

  const { t } = useTranslation();

  if (isLoadingMessageTemplate) {
    return <div>Loading...</div>;
  }

  const onSend = () => {
    sendReply(
      {
        email_template_id: messageTemplate?.id,
        body: body || '',
      },
      {
        onSuccess: async () => {
          onClick?.();
          toast.success(t(tokens.automator.resolutions.dialogs.send_email_thread_reply.reply_sent));
        },
      }
    );
  };

  return (
    <Stack
      direction="column"
      gap={2}
      width="70%"
    >
      <HtmlEditor
        content={body}
        onChange={(newBody) => {
          if (newBody === body) {
            return;
          }
          setBody(newBody);
        }}
        insertVariable={insertVariable}
      />

      <ActionButton
        icon={<Email />}
        label={t(tokens.automator.resolutions.dialogs.send_email_thread_reply.send_reply)}
        onClick={() => onSend()}
        isLoading={isLoading}
        variant="contained"
        color="primary"
        size="small"
      />
    </Stack>
  );
};
