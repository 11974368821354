import { useFetchOrderEvents } from '../../../../api/automator/orders/useFetchOrderEvents';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import Typography from '@mui/material/Typography';
import { getAlphabeticFormatFromDate } from '../../../../helpers/getAlphabeticFormatFromDate';
import Stack from '@mui/material/Stack';

interface OrderSummaryOrderEventsProps {
  orderId: number;
}

export const OrderSummaryOrderEvents = ({ orderId }: OrderSummaryOrderEventsProps) => {
  const { data: orderEvents, isLoading } = useFetchOrderEvents(orderId);

  //const { t } = useTranslation();

  if (isLoading) {
    return <>...loading</>;
  }

  const sortedOrderEvents = orderEvents?.sort((a, b) => {
    // First, compare the dates
    const dateComparison = new Date(b.date).getTime() - new Date(a.date).getTime();

    if (dateComparison !== 0) {
      // If the dates are not the same, return the result of date comparison
      return dateComparison;
    } else {
      // Here, it means the dates are the same so we compare the times

      if (a.time && b.time) {
        // Parse times to Date objects and compare
        const timeA = new Date(`1970-01-01T${a.time}Z`).getTime();
        const timeB = new Date(`1970-01-01T${b.time}Z`).getTime();
        return timeB - timeA;
      } else if (a.time || b.time) {
        // If only one of the times exist, consider that as being later
        return a.time ? -1 : 1;
      } else {
        // If neither time exist, consider the items as equal
        return 0;
      }
    }
  });

  return (
    <Table>
      {sortedOrderEvents?.map((orderEvent) => (
        <TableRow key={orderEvent.orderId}>
          <SlimTableCell>
            <Stack direction="row">
              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
                width={100}
              >
                {getAlphabeticFormatFromDate(new Date(orderEvent.date), false)}
              </Typography>

              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
              >
                {orderEvent.time ? orderEvent.time.slice(0, 5) : ''}
              </Typography>
            </Stack>
          </SlimTableCell>
          <SlimTableCell>
            <Typography
              variant="body2"
              color="textPrimary"
            >
              {orderEvent.type}
            </Typography>
          </SlimTableCell>
        </TableRow>
      ))}
    </Table>
  );
};
