import { RESOLUTION_CASES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useAuthorizedPost } from '../../useAuthorizedPost';
import { ResolutionCaseQueryKey } from './ResolutionCaseQueryKey';
import { useUpdateData } from '../../useUpdateData';
import { useResolutionDossierQueryKeysUpdater } from './useResolutionDossierQueryKeysUpdater';
import ResolutionDossiers, {
  AGGREGATE_KEY,
} from '../../../domain/automator/resolutionDossiers/ResolutionDossiers';
import { OrderMessageThreadsQueryKey } from '../../queryKeys/OrderMessageThreadsQueryKey';

export const useHandleResolutionCase = (resolutionCaseId: number) => {
  const url = useAutomatorApiAccountUrl(RESOLUTION_CASES_PATH + '/' + resolutionCaseId + `/handle`);

  const updater = useResolutionDossierQueryKeysUpdater();
  const updateData = useUpdateData(updater, AGGREGATE_KEY);

  const onSuccess = (dossiers: ResolutionDossiers) => {
    updateData(dossiers);
  };

  return useAuthorizedPost<ResolutionDossiers, undefined>(
    url,
    [OrderMessageThreadsQueryKey(undefined), ResolutionCaseQueryKey(undefined)],
    undefined,
    onSuccess
  );
};
