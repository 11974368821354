import TableRow from '@mui/material/TableRow';
import Return from '../../../../domain/automator/returns/Return';
import { ReturnItemCard } from './ReturnItemCard';
import { ReturnItemStatus } from '../../../../domain/automator/returns/ReturnItemStatus';
import { ReturnReasonStack } from './ReturnReasonStack';
import ReturnItemButtons from './ReturnItemButtons';
import { SlimTableCell } from '../../../../components/SlimTableCell';
import DaysSinceRegistrationText from './DaysUntilExpirationText';
import AcceptOrRejectReturnItemButtons from './AcceptOrRejectReturnItemButtons';
import { ReturnItemFilter } from '../../../../domain/automator/returns/ReturnItemFilter';
import ReturnItem from '../../../../domain/automator/returns/ReturnItem';
import { ScannedIconChip } from '../../returnScans/components/ScannedIcon';

interface ReturnTableRowProps {
  return_: Return;
  currentTab: ReturnItemFilter;
  showDaysRegistered: boolean;
}

export const ReturnTableRow = ({
  return_,
  currentTab,
  showDaysRegistered,
}: ReturnTableRowProps) => {
  const filterItems = (returnItems: ReturnItem[], filter: ReturnItemFilter) => {
    switch (filter) {
      case ReturnItemFilter.OVERVIEW:
        return returnItems.filter((returnItem) => {
          return (
            (returnItem.status == ReturnItemStatus.REGISTERED && !returnItem.isHandled) ||
            (returnItem.status == ReturnItemStatus.ON_HOLD && !returnItem.isHandled) ||
            (returnItem.status == ReturnItemStatus.REPAIR && !returnItem.isScanned) ||
            (returnItem.status == ReturnItemStatus.EXCHANGE && !returnItem.isScanned)
          );
        });
      case ReturnItemFilter.ON_HOLD:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.ON_HOLD);
      case ReturnItemFilter.REPAIR:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.REPAIR);
      case ReturnItemFilter.EXCHANGE:
        return returnItems.filter((returnItem) => returnItem.status == ReturnItemStatus.EXCHANGE);
      case ReturnItemFilter.HANDLED:
        return returnItems.filter((returnItem) => returnItem.isHandled);
    }
  };

  const returnItems = return_.returnItems;

  return (
    <>
      {filterItems(returnItems, currentTab).map((returnItem, index) => {
        const hasBorderBottom = returnItems.length === 1 || index === returnItems.length - 1;

        return (
          <TableRow key={returnItem.id}>
            <SlimTableCell width={10}>
              {returnItem.isScanned ? <ScannedIconChip /> : <></>}
            </SlimTableCell>

            <SlimTableCell
              width={1}
              hasBorderBottom={hasBorderBottom}
            >
              <ReturnItemCard
                key={returnItem.id}
                quantity={returnItem.quantityUnprocessed}
                returnItem={returnItem}
                return_={return_}
                showTransporterInfo={true}
              />
            </SlimTableCell>

            <SlimTableCell
              align={'left'}
              hasBorderBottom={hasBorderBottom}
            >
              <ReturnReasonStack returnItem={returnItem} />
            </SlimTableCell>

            {showDaysRegistered && (
              <SlimTableCell
                hasBorderBottom={hasBorderBottom}
                align="right"
              >
                <DaysSinceRegistrationText daysSinceRegistration={return_.daysSinceRegistration} />
              </SlimTableCell>
            )}

            <SlimTableCell
              hasBorderBottom={hasBorderBottom}
              align="right"
            >
              <ReturnItemButtons
                returnItem={returnItem}
                currentTab={currentTab}
              />
            </SlimTableCell>

            <SlimTableCell
              hasBorderBottom={hasBorderBottom}
              align="right"
            >
              <AcceptOrRejectReturnItemButtons returnItem={returnItem} />
            </SlimTableCell>
          </TableRow>
        );
      })}
    </>
  );
};
