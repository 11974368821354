import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ResolutionCase from '../../../../domain/automator/resolutionDossiers/ResolutionCase';
import DistributionPartyChip from '../../shipments/components/DistributionPartyChip';
import ShipmentStatusChip from '../../shipments/components/ShipmentEventStatus';
import { ShipmentEventLoadingStatus } from '../../../../domain/automator/shipments/ShipmentEventLoadingStatus';
import { getDateStringFromDate } from '../../../../helpers/getDateStringFromDate';
import ShipmentTransporterInfo from '../../shipments/components/ShipmentTransporterInfo';
import { useFetchOrder } from '../../../../api/automator/orders/useFetchOrder';
import { useFetchOrderShipment } from '../../../../api/automator/orders/useFetchOrderShipment';

interface ResolutionCaseInfoCardProps {
  resolutionCase: ResolutionCase;
}

export const ResolutionCaseInfoCard = ({ resolutionCase }: ResolutionCaseInfoCardProps) => {
  const hasBolCaseNumber = resolutionCase.bolCaseNumber !== null;

  const { data: order, isLoading: isLoadingOrder } = useFetchOrder(
    resolutionCase.messageThread.orderId
  );

  const { data: shipment, isLoading: isLoadingShipment } = useFetchOrderShipment(
    resolutionCase.messageThread.orderId
  );

  if (isLoadingOrder || isLoadingShipment) {
    return <div>Loading...</div>;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          direction="column"
          gap={1}
        >
          {hasBolCaseNumber && (
            <Stack
              alignItems="center"
              alignContent="center"
              direction="row"
              justifyContent="space-between"
              gap={1}
            >
              <Typography variant="body2">Case</Typography>
              <Typography variant="body2">{resolutionCase.bolCaseNumber}</Typography>
            </Stack>
          )}

          <Stack
            alignItems="center"
            alignContent="center"
            direction="row"
            justifyContent="space-between"
            gap={1}
          >
            <Typography variant="body2">Type</Typography>
            <DistributionPartyChip distributionParty={order!.distributionParty} />
          </Stack>

          {shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Status</Typography>
              <ShipmentStatusChip
                shipmentEventsLoadingStatus={ShipmentEventLoadingStatus.NOT_APPLICABLE}
                shipment={shipment}
              />
            </Stack>
          )}

          {shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Leverdatum</Typography>
              <Typography variant="body2">
                {getDateStringFromDate(new Date(shipment.expectedDeliveryDate), false)}
              </Typography>
            </Stack>
          )}

          {shipment && (
            <Stack
              alignItems="center"
              alignContent="center"
              justifyContent="space-between"
              direction="row"
              gap={1}
            >
              <Typography variant="body2">Tracking</Typography>

              <ShipmentTransporterInfo shipment={shipment!} />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
