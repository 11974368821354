import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { AutoMailEventRulesListTable } from './components/AutoMailEventRulesListTable';
import ActionButton from '../../../components/ActionButton';
import { useOpenDialog } from '../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../store/dialogs/DialogId';
import { useTranslation } from 'react-i18next';
import { useFetchEventRules } from '../../../api/automator/event-rules/useFetchEventRules';
import { EventRuleType } from '../../../domain/automator/eventRules/EventRuleType';

const AutoMailPage = () => {
  const { data: eventRules, isLoading } = useFetchEventRules(EventRuleType.AUTO_EMAIL);

  const openDialog = useOpenDialog(DialogId.CREATE_EMAIL_EVENT_RULE);

  const { t } = useTranslation();

  return (
    <AccountPage
      buttons={[
        <ActionButton
          key={1}
          label={t(tokens.automator.auto_mail.form.create_event_rule)}
          onClick={() => openDialog()}
          isLoading={isLoading}
        />,
      ]}
      title={tokens.automator.auto_mail.event_rules}
    >
      <AutoMailEventRulesListTable
        eventRules={eventRules?.eventRules}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default AutoMailPage;
