import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';

interface EventRuleTriggerChipProps {
  trigger: EventTrigger;
}

const EventRuleTriggerChip = ({ trigger }: EventRuleTriggerChipProps) => {
  const { t } = useTranslation();

  const determineLabel = (trigger: EventTrigger) => {
    switch (trigger) {
      case EventTrigger.NEW_ORDER:
        return tokens.automator.auto_mail.triggers.new_order;
      case EventTrigger.ORDER_IS_PLACED_DOUBLE:
        return tokens.automator.auto_mail.triggers.order_is_placed_double;
      case EventTrigger.CUSTOMER_IS_RECURRING:
        return tokens.automator.auto_mail.triggers.customer_is_recurring;
      case EventTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED:
        return tokens.automator.auto_mail.triggers.shipment_track_and_trace_uploaded;
      case EventTrigger.SHIPMENT_SHIPPED:
        return tokens.automator.auto_mail.triggers.shipment_send;
      case EventTrigger.SHIPMENT_DELIVERED:
        return tokens.automator.auto_mail.triggers.shipment_delivered;
      case EventTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT:
        return tokens.automator.auto_mail.triggers.shipment_at_pickup_point;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER:
        return tokens.automator.auto_mail.triggers.shipment_returned_to_sender;
      case EventTrigger.RETURN_REGISTERED:
        return tokens.automator.auto_mail.triggers.return_registered;
      case EventTrigger.RETURN_HANDLED:
        return tokens.automator.auto_mail.triggers.return_handled;
      case EventTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.auto_mail.triggers.recoverable_return_item_registered;
      case EventTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
        return tokens.automator.auto_mail.triggers.recoverability_unclear_return_item_registered;
      case EventTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.auto_mail.triggers.not_recoverable_return_item_registered;
      case EventTrigger.EXPECTED_DELIVERY_DATE_REACHED:
        return tokens.automator.auto_mail.triggers.expected_delivery_date_reached;

      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER:
        return tokens.automator.auto_mail.triggers.shipment_returned_to_sender_by_transporter;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT:
        return tokens.automator.auto_mail.triggers.shipment_returned_to_sender_by_pickup_point;
      case EventTrigger.PICKUP_POINT_WILL_RETURN_TO_SENDER:
        return tokens.automator.auto_mail.triggers.pickup_point_will_return_to_sender;
      case EventTrigger.SHIPMENT_OVERDUE:
        return tokens.automator.auto_mail.triggers.shipment_overdue;
      case EventTrigger.RETURN_ITEM_REJECTED:
        return tokens.automator.auto_mail.triggers.return_item_rejected;
      case EventTrigger.RETURN_ITEM_EXPIRED:
        return tokens.automator.auto_mail.triggers.return_item_expired;
      case EventTrigger.ORDER_OVERDUE:
        return tokens.automator.auto_mail.triggers.order_overdue;

      default:
        return trigger + '.';
    }
  };

  //
  // const determineColor = (_: EventTrigger) => {
  //   return 'info';
  //
  //   // switch (trigger) {
  //   //   case EventTrigger.NEW_ORDER:
  //   //   case EventTrigger.CUSTOMER_IS_RECURRING:
  //   //   case EventTrigger.ORDER_IS_PLACED_DOUBLE:
  //   //   case EventTrigger.EXPECTED_DELIVERY_DATE_REACHED:
  //   //     return 'primary';
  //   //   case EventTrigger.RETURN_HANDLED:
  //   //   case EventTrigger.RETURN_REGISTERED:
  //   //   case EventTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
  //   //   case EventTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
  //   //   case EventTrigger.NOT_RECOVERABLE_RETURN_ITEM_REGISTERED:
  //   //   case EventTrigger.RETURN_ITEM_REJECTED:
  //   //   case EventTrigger.RETURN_ITEM_EXPIRED:
  //   //     return 'info';
  //   //   case EventTrigger.SHIPMENT_SHIPPED:
  //   //   case EventTrigger.SHIPMENT_TRACK_AND_TRACE_UPLOADED:
  //   //   case EventTrigger.SHIPMENT_DELIVERED:
  //   //   case EventTrigger.SHIPMENT_DELIVERED_AT_PICKUP_POINT:
  //   //   case EventTrigger.SHIPMENT_RETURNED_TO_SENDER:
  //   //   case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER:
  //   //   case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT:
  //   //   case EventTrigger.PICKUP_POINT_WILL_RETURN_TO_SENDER:
  //   //   case EventTrigger.SHIPMENT_OVERDUE:
  //   //     return 'secondary';
  //   //   default:
  //   //     return 'warning';
  //   // }
  // };

  return (
    <Chip
      style={{ borderRadius: 4 }}
      size="small"
      label={t(determineLabel(trigger))}
      variant="filled"
      color={'info'}
    />
  );
};

export default EventRuleTriggerChip;
