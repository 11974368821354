import Alert from '../../../../domain/automator/alerts/Alert';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { toast } from 'react-hot-toast';
import { useExecuteAlerts } from '../../../../api/automator/alerts/useExecuteAlerts';

interface ExecuteAllAlertActionsButtonProps {
  alerts: Alert[];
}

export const ExecuteAllAlertActionsButton = ({ alerts }: ExecuteAllAlertActionsButtonProps) => {
  const { t } = useTranslation();

  const { mutate: executeAlerts, isLoading: isLoadingExecuteAlerts } = useExecuteAlerts();

  const onExecuteAll = () => {
    executeAlerts(
      { alert_ids: alerts.map((alert) => alert.id) },
      {
        onSuccess: () => {
          toast.success(t(tokens.automator.alerts.dismissed));
        },
      }
    );
  };

  return (
    <ActionButton
      icon={<PlayCircleOutlineIcon />}
      label={t(tokens.automator.alerts.execute_all)}
      onClick={onExecuteAll}
      size={'small'}
      color={'success'}
      isLoading={isLoadingExecuteAlerts}
    />
  );
};
