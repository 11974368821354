import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { LabelTypeToggleButton } from '../../orders/components/LabelTypeToggleButton';
import { debounce } from '@mui/material';
import { LabelType } from '../../../../domain/automator/products/LabelType';
import { toast } from 'react-hot-toast';
import { tokens } from '../../../../locales/translationTokens';
import TextField from '@mui/material/TextField';
import { memo, useCallback, useEffect, useState } from 'react';
import { useUpdateLabelTypeRules } from '../../../../api/automator/label-type-rules/useUpdateLabelTypeRules';
import Stack from '@mui/material/Stack';
import { TooltippedProductThumbnail } from '../../orders/components/TooltippedProductThumbnail';
import AutomatorProduct from '../../../../domain/automator/products/AutomatorProduct';
import Typography from '@mui/material/Typography';
import { ProductGroupChip } from './ProductGroupChip';
import { AddProductGroupToProductChip } from './AddProductGroupToProductChip';
import { useRemoveGroupFromProduct } from '../../../../api/automator/products/useRemoveGroupFromProduct';
import { ProductTableRowOffer } from './ProductTableRowOffer';
import ProductGroup from '../../../../domain/automator/products/ProductGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';

interface ProductTableRowProps {
  product: AutomatorProduct;
  productGroups: ProductGroup[];
}

const ProductTableRow = ({ product, productGroups }: ProductTableRowProps) => {
  const { t } = useTranslation();
  const labelTypeRule = product.labelTypeRule;

  const [maxItems, setMaxItems] = useState(labelTypeRule.maxItemsForMailbox.toString());
  const [labelType, setLabelType] = useState(labelTypeRule.labelType);
  const [removingProductGroupId, setRemovingProductGroupId] = useState<number | null>(null);

  useEffect(() => {
    if (labelTypeRule.labelType != labelType) {
      setLabelType(labelTypeRule.labelType);
    }

    if (labelTypeRule.maxItemsForMailbox != Number(maxItems)) {
      setMaxItems(labelTypeRule.maxItemsForMailbox.toString());
    }
  }, [product]);

  const { mutate: updateLabelTypeRules } = useUpdateLabelTypeRules();

  const { mutate: removeProductGroup } = useRemoveGroupFromProduct(product.id);

  const openUpdateProductInternalReferenceDialog = useOpenDialog(
    DialogId.UPDATE_OFFER_INTERNAL_REFERENCE
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateMaxItemsFormMailbox = useCallback(
    debounce((maxItems: string) => {
      const maxItemsNumber = Number(maxItems);
      if (maxItems && maxItemsNumber !== labelTypeRule.maxItemsForMailbox && maxItemsNumber >= 1) {
        doUpdateLabelTypeRule(maxItemsNumber, labelTypeRule.labelType);
      }
    }, 500),
    []
  );

  const doUpdateLabelTypeRule = (maxItems: number | null, labelType: LabelType) => {
    const data = {
      label_type_rule_ids: [labelTypeRule.id],
      max_items_for_mailbox: maxItems,
      label_type: labelType,
    };

    updateLabelTypeRules(data, {
      onSuccess: () => {
        toast.success(t(tokens.automator.ltr.label_type_changed));
      },
    });
  };

  const doRemoveGroupToProduct = (productGroupId: number) => {
    const data = {
      product_group_id: productGroupId,
    };

    setRemovingProductGroupId(productGroupId);

    removeProductGroup(data, {
      onSuccess: () => {
        setRemovingProductGroupId(null);
        toast.success(t(tokens.automator.products.group_removed));
      },
    });
  };

  const productProductGroups = product.productGroups;
  const sortedProductGroups = productProductGroups.sort((a, b) => (a.id < b.id ? -1 : 1));

  const offers = product.offers;
  const sortedOffers = [...offers].sort((a, b) => (a.retailer.id < b.retailer.id ? -1 : 1));

  const internalReference =
    product.offers.find((offer) => offer.internalReference != null)?.internalReference ?? '';

  return (
    <TableRow key={labelTypeRule.id}>
      <TableCell>
        <TooltippedProductThumbnail
          thumbnailUrl={product.thumbnailUrl}
          productTitle={product.title + ' | ' + product.ean}
          height={50}
          onClick={() => window.open(product.productPageUrl, '_blank')}
        />
      </TableCell>
      <TableCell>
        <LabelTypeToggleButton
          value={labelType}
          onChange={(labelType) => {
            setLabelType(labelType);
            doUpdateLabelTypeRule(labelTypeRule.maxItemsForMailbox, labelType);
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          style={{ width: '55px' }}
          disabled={labelTypeRule.labelType != LabelType.MAILBOX}
          value={maxItems}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) > 0 || value == '') {
              setMaxItems(value);
              debouncedUpdateMaxItemsFormMailbox(e.target.value);
            }
          }}
          onBlur={() => debouncedUpdateMaxItemsFormMailbox(maxItems)}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          size={'small'}
        />
      </TableCell>

      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={() =>
          openUpdateProductInternalReferenceDialog({
            productId: product.id,
            internalReference: internalReference,
          })
        }
      >
        <Typography variant={'body2'}>{internalReference}</Typography>
      </TableCell>

      <TableCell>
        <Stack
          direction="row"
          gap={0.25}
        >
          {sortedProductGroups.map((productGroup) =>
            productGroup.id === removingProductGroupId ? (
              <CircularProgress key={productGroup.id} />
            ) : (
              <ProductGroupChip
                key={productGroup.id}
                productGroup={productGroup}
                onClick={(_) => {}}
                onDelete={(productGroupId) => {
                  doRemoveGroupToProduct(productGroupId);
                }}
              />
            )
          )}
          {productGroups.length > productProductGroups.length && (
            <AddProductGroupToProductChip product={product} />
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Stack
          direction="column"
          gap={0.5}
        >
          {sortedOffers.map((offer, index) => (
            <ProductTableRowOffer
              key={index}
              offer={offer}
            />
          ))}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default memo(ProductTableRow);
