import { EVENT_RULES_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import EventRules from '../../../domain/automator/eventRules/EventRules';
import { EventRulesQueryKey } from '../../queryKeys/EventRulesQueryKey';
import { EventRuleType } from '../../../domain/automator/eventRules/EventRuleType';

export const useFetchEventRules = (type: EventRuleType) => {
  const url = useAutomatorApiAccountUrl(EVENT_RULES_PATH + '?type=' + type);
  return useFetchData<EventRules>(url, EventRulesQueryKey(type));
};
