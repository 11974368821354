import { ApplicationDialog } from '../../../../components/ApplicationDialog';
import { tokens } from '../../../../locales/translationTokens';
import { useTranslation } from 'react-i18next';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { useIsDialogOpen } from '../../../../store/dialogs/useIsDialogOpen';
import { useCloseDialog } from '../../../../store/dialogs/useCloseDialog';
import { StartEmailThreadForm, StartEmailThreadMode } from '../components/StartEmailThreadForm';
import { RecipientType } from '../../../../domain/automator/messages/RecipientType';

export const CreateEmailThreadDialog = () => {
  const { t } = useTranslation();

  const id = DialogId.CREATE_EMAIL_THREAD;
  const { isOpen, data } = useIsDialogOpen(id);
  const closeDialog = useCloseDialog(id);

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={DialogId.CREATE_EMAIL_THREAD}
          title={t(tokens.automator.resolutions.dialogs.send_email.dialog_title)}
          maxWidth={data.messageTemplateId ? 'md' : 'lg'}
        >
          <StartEmailThreadForm
            alertId={data.alertId}
            phase={data.phase}
            aggregateId={data.aggregateId}
            aggregateIds={data.aggregateIds || []}
            mode={data.aggregateIds ? StartEmailThreadMode.BULK : StartEmailThreadMode.SINGLE}
            onClick={closeDialog}
            body={data.body}
            messageTemplateId={data.messageTemplateId}
            recipientType={data.recipientType}
            orderId={data.orderId}
          />
        </ApplicationDialog>
      )}
    </>
  );
};

export interface CreateEmailThreadDialogData {
  orderId: number | null; // null if bulk
  alertId?: number;
  phase: ThreadPhase | null;
  aggregateId: number | null;
  aggregateIds?: number[];
  body: string | null;
  messageTemplateId: number | null;
  recipientType: RecipientType;
}
