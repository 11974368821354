import EventTrigger from '../../../../domain/automator/eventRules/EventTrigger';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import { DistributionParty } from '../../../../domain/automator/orders/DistributionParty';
import ActionButton from '../../../../components/ActionButton';
import { DialogId } from '../../../../store/dialogs/DialogId';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';

interface EventRuleInfoButtonProps {
  eventTrigger: EventTrigger;
  distributionParty: DistributionParty | null;
}

export const EventRuleInfoButton = ({
  eventTrigger,
  distributionParty,
}: EventRuleInfoButtonProps) => {
  const { t } = useTranslation();

  const openDialog = useOpenDialog(DialogId.INFO);

  const determineTooltip = () => {
    switch (eventTrigger) {
      case EventTrigger.RECOVERABILITY_UNCLEAR_RETURN_ITEM_REGISTERED:
        return tokens.automator.alerts.event_rule_info;
      case EventTrigger.ORDER_IS_PLACED_DOUBLE:
        return tokens.automator.alerts.event_rule_info.order_is_placed_double;
      case EventTrigger.PICKUP_POINT_WILL_RETURN_TO_SENDER:
        return tokens.automator.alerts.event_rule_info.order_is_placed_double;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_TRANSPORTER:
        return distributionParty == DistributionParty.BOL
          ? tokens.automator.alerts.event_rule_info.vvb_shipment_returned_to_sender_by_transporter
          : tokens.automator.alerts.event_rule_info
              .non_vvb_shipment_returned_to_sender_by_transporter;
      case EventTrigger.ORDER_OVERDUE:
        return tokens.automator.alerts.event_rule_info.order_overdue;
      case EventTrigger.SHIPMENT_RETURNED_TO_SENDER_BY_PICKUP_POINT:
        return tokens.automator.alerts.event_rule_info.shipment_returned_to_sender_by_pickup_point;
      case EventTrigger.RETURN_ITEM_REJECTED:
        return tokens.automator.alerts.event_rule_info.return_item_rejected;
      case EventTrigger.SHIPMENT_OVERDUE:
        return distributionParty == DistributionParty.BOL
          ? tokens.automator.alerts.event_rule_info.vvb_shipment_overdue
          : tokens.automator.alerts.event_rule_info.non_vvb_shipment_overdue;
      case EventTrigger.RECOVERABLE_RETURN_ITEM_REGISTERED:
        return tokens.automator.alerts.event_rule_info.recoverable_return_item_registered;
      case EventTrigger.RETURN_ITEM_EXPIRED:
        return tokens.automator.alerts.event_rule_info.return_item_expired;
    }
  };

  return (
    <ActionButton
      style={{ padding: 0, borderRadius: '5px' }}
      label={t(tokens.automator.alerts.event_rule_info.info)}
      color="info"
      size="small"
      variant="outlined"
      onClick={() => {
        openDialog({
          title: tokens.automator.alerts.event_rule_info.info,
          message: determineTooltip() as string,
        });
      }}
    />
  );
};
