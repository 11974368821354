import Typography from '@mui/material/Typography';
import ShipmentDetails from '../../../../domain/automator/shipments/ShipmentDetails';
import RepeatCustomerChip from './RepeatCustomerChip';
import Stack from '@mui/material/Stack';
import DoublePlacedOrderChip from './DoublePlacedOrderChip';

interface OrderRecipientProps {
  shipmentDetails: ShipmentDetails;
  isRepeatCustomer?: boolean;
  isDoublePlacement?: boolean;
}

export const OrderRecipient = ({
  shipmentDetails,
  isRepeatCustomer,
  isDoublePlacement,
}: OrderRecipientProps) => {
  return (
    <Stack
      direction={'row'}
      gap={1}
      alignItems={'center'}
    >
      <Typography
        variant="subtitle1"
        display={'flex'}
        gap={0.2}
        noWrap
      >
        {shipmentDetails.firstName} {shipmentDetails.surname}
      </Typography>
      {isRepeatCustomer && <RepeatCustomerChip />}
      {isDoublePlacement && <DoublePlacedOrderChip />}
    </Stack>
  );
};
