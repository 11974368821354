import Stack from '@mui/material/Stack';
import { MessageTemplateSelection } from './MessageTemplateSelection';
import { MessageTemplateType } from '../../../../domain/automator/messages/MessageTemplateType';
import { ThreadPhase } from '../../../../domain/automator/messages/ThreadPhase';
import { EmailVariableSelection } from '../../shipments/components/EmailVariableSelection';
import { TemplateVariableToggleButtons } from './TemplateVariableToggleButtons';
import { useState } from 'react';

interface TemplateVariableSelectionProps {
  phase: ThreadPhase | null;
  onSelectTemplate: (messageTemplateId: number) => void;
  selectedTemplateId: number | null;
  onSelectVariable: (variable: string) => void;
}

export enum TemplateVariableToggleType {
  VARIABLES = 'VARIABLES',
  ORDER_TEMPLATES = 'ORDER_TEMPLATES',
  SHIPMENT_TEMPLATES = 'SHIPMENT_TEMPLATES',
  RETURN_TEMPLATES = 'RETURN_TEMPLATES',
}

export const TemplateVariableSelection = ({
  phase,
  onSelectTemplate,
  selectedTemplateId,
  onSelectVariable,
}: TemplateVariableSelectionProps) => {
  const determineInitialTemplateVariableToggleType = (
    phase: ThreadPhase | null
  ): TemplateVariableToggleType => {
    switch (phase) {
      case ThreadPhase.ORDER:
        return TemplateVariableToggleType.ORDER_TEMPLATES;
      case ThreadPhase.SHIPMENT:
        return TemplateVariableToggleType.SHIPMENT_TEMPLATES;
      case ThreadPhase.RETURN:
        return TemplateVariableToggleType.RETURN_TEMPLATES;
    }
    return TemplateVariableToggleType.VARIABLES;
  };

  const [templateVariableToggleType, setTemplateVariableToggleType] =
    useState<TemplateVariableToggleType>(determineInitialTemplateVariableToggleType(phase));

  const determineTemplatePhase = (type: TemplateVariableToggleType) => {
    switch (type) {
      case TemplateVariableToggleType.ORDER_TEMPLATES:
        return ThreadPhase.ORDER;
      case TemplateVariableToggleType.SHIPMENT_TEMPLATES:
        return ThreadPhase.SHIPMENT;
      case TemplateVariableToggleType.RETURN_TEMPLATES:
        return ThreadPhase.RETURN;
      default:
        throw new Error('Invalid template variable toggle type');
    }
  };

  return (
    <Stack
      direction="column"
      gap={1.5}
    >
      <TemplateVariableToggleButtons
        selected={templateVariableToggleType}
        onSelect={setTemplateVariableToggleType}
      />

      {(templateVariableToggleType == TemplateVariableToggleType.ORDER_TEMPLATES ||
        templateVariableToggleType == TemplateVariableToggleType.SHIPMENT_TEMPLATES ||
        templateVariableToggleType == TemplateVariableToggleType.RETURN_TEMPLATES) && (
        <MessageTemplateSelection
          type={MessageTemplateType.MANUAL}
          onSelect={onSelectTemplate}
          selected={selectedTemplateId}
          phase={determineTemplatePhase(templateVariableToggleType)}
          recoverability={null}
        />
      )}

      {templateVariableToggleType == TemplateVariableToggleType.VARIABLES && (
        <EmailVariableSelection onSelectVariable={onSelectVariable} />
      )}
    </Stack>
  );
};
