import { ALERTS_PATH } from '../automatorApiPaths';
import { useAutomatorApiAccountUrl } from '../useAutomatorApiAccountUrl';
import { useFetchData } from '../../useFetchData';
import Alerts from '../../../domain/automator/alerts/Alerts';
import { AlertsQueryKey } from '../../queryKeys/AlertsQueryKey';
import { AlertStatus } from '../../../domain/automator/alerts/AlertStatus';

export const useFetchAlerts = (status: AlertStatus) => {
  const url = useAutomatorApiAccountUrl(ALERTS_PATH) + '?status=' + status;
  return useFetchData<Alerts>(url, AlertsQueryKey(status));
};
