import ResolutionDossier from './ResolutionDossier';
import ResolutionDossiersReport from './ResolutionDossiersReport';
import Pagination from '../../Pagination';

export default interface ResolutionDossiers {
  [AGGREGATE_KEY]: ResolutionDossier[];
  report: ResolutionDossiersReport;
  pagination: Pagination | null;
}

export const AGGREGATE_KEY = 'resolutionDossiers';
