import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/translationTokens';
import ActionButton from '../../../../components/ActionButton';
import { useOpenDialog } from '../../../../store/dialogs/useOpenDialog';
import { DialogId } from '../../../../store/dialogs/DialogId';
import MessageThread from '../../../../domain/automator/messages/MessageThread';

interface OpenSendEmailThreadReplyButtonProps {
  messageThread: MessageThread;
}

export const OpenSendEmailThreadReplyButton = ({
  messageThread,
}: OpenSendEmailThreadReplyButtonProps) => {
  const openDialog = useOpenDialog(DialogId.SEND_MESSAGE_THREAD_REPLY);

  const { t } = useTranslation();

  return (
    <ActionButton
      label={t(tokens.automator.resolutions.dialogs.send_email_thread_reply.send_reply)}
      onClick={() => {
        openDialog({ messageThread: messageThread, messageTemplateId: null });
      }}
      variant="contained"
      color="primary"
      size="small"
    />
  );
};
