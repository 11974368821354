import { fillMissingKeys, tokens, TranslationTokens } from '../translationTokens';

export const nl: TranslationTokens = fillMissingKeys(tokens, {
  common: {
    language_changed: 'Taal aangepast',
    created_date_time: 'Aanmaakdatum',
    loading: 'Bezig met laden...',
    no_items: 'Geen items gevonden',
    actions: {
      actions: 'Acties',
      delete: 'Verwijderen',
    },
    netherlands: 'Nederland',
    belgium: 'België',
    close: 'Sluiten',
    save: 'Opslaan',
    add: 'Toevoegen',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
    language_change: 'Verander taal',
    action_error: 'Er is iets mis gegaan, probeer het later opnieuw.',
    select: 'Selecteer',
    on: 'Aan',
    off: 'Uit',
    required: 'Vereist',
    incorrect: 'Onjuist',
    address: {
      zip_code: 'Postcode',
      city: 'Plaats',
      house_number: 'Huisnummer',
      house_number_extension: 'Toevoeging',
      street_name: 'Straat',
      country: 'Land',
      address: 'Adres',
    },
    days_of_the_week: {
      sunday: 'zondag',
      monday: 'maandag',
      tuesday: 'dinsdag',
      wednesday: 'woensdag',
      thursday: 'donderdag',
      friday: 'vrijdag',
      saturday: 'zaterdag',
    },
    recipient_type: {
      recipient_type: 'Ontvangers-type',
      transporter: 'Vervoerder',
      seller: 'Verkoper',
      customer: 'Klant',
      bol: 'Partnerservice',
    },
    email_status: {
      email_status: 'E-mail status',
      sent: 'Verzonden',
      cancelled: 'Geannuleerd',
      pending: 'In afwachting',
    },
    pick_step_type: {
      request_label: '*Label aanvragen',
      fetch_label: '*Label ophalen',
      approval: '*Goedkeuring',
      request_shipment: '*Verzending aanvragen',
      fetch_shipment: '*Verzending ophalen',
    },
    resend_status: {
      open: '*Open',
      handled: '*Afgehandeld',
    },
  },
  nav: {
    auto_mail: 'AutoMail',
    orders: 'Bestellingen',
    vvb_orders: 'VVB',
    non_vvb_orders: 'Non-VVB',
    pick_sessions: 'Pakketlabels',
    products: 'Producten',
    retailers: 'Account API',
    returns: 'Retouren',
    return_scans: 'Retourscans',
    email_threads: 'E-mail threads',
    resolution_dossiers: 'CaseHub',
    resends: 'Nasturen',
    reports: 'Rapportages',
    shipments: 'TrackingView',
    login: 'Inloggen',
    register: 'Registreren',
    event_rules: 'AutoMail',
    alerts: 'Meldingen',
    error: 'Error',
    logout: 'Uitloggen',
    settings: 'Instellingen',
    subscribe: 'Abonnement',
    customer_portal: 'Klantportaal',
    support: 'Support / Feedback',
    pick_sessions_needing_approval: 'Bevestig!',
  },
  auth: {
    email_address: 'Email adres',
    password: 'Wachtwoord',
    invalid_email_address: 'Ongeldig email adres',
    email_required: 'Email vereist',
    password_required: 'Wachtwoord vereist',
    login: {
      login: 'Login',
      incorrect_password: 'Incorrect wachtwoord en/of email adres',
      email_address_already_in_use: 'Email adres is al in gebruik',
      forgot_password: 'Wachtwoord vergeten?',
      unknown_error: 'Onbekende foutmelding',
    },
    register: {
      password_min_length: 'Een wachtwoord moet minimaal 8 karakters lang zijn',
      password_alpha: 'Een wachtwoord moet minsten 1 letter bevatten',
      password_numeric: 'Een wachtwoord moet minsten 1 cijfer bevatten',
      passwords_must_match: 'De wachtwoorden moeten overeenkomen',
      confirm_password_required: 'Bevestiging wachtwoord vereist',
      confirm_password: 'Bevestig wachtwoord',
      register: 'Registreren',
    },
    request_new_password: {
      request_new_password: 'Nieuw wachtwoord aanvragen',
      do_request_new_password: 'Aanvragen',
      set_new_password: 'Nieuw wachtwoord instellen',
      token_invalid: 'Ongeldig token, probeer nogmaals een nieuw wachtwoord aan te vragen.',
      email_sent:
        'Een email is verstuurd naar het opgegeven email adres om het wachtwoord opnieuw in te stellen.',
      new_password_set: 'Nieuw wachtwoord ingesteld, je kunt nu inloggen met je nieuwe wachtwoord.',
    },
  },
  automator: {
    products: {
      products: 'Producten',
      search_products: '*Zoek producten',
      product: '*Product',
      label_type: '*Labeltype',
      more_than: '*Meer dan',
      label_notes: '*Labelnotities',
      groups: '*Groepen',
      offers: '*Aanbiedingen',
      group_added: '*Groep toegevoegd',
      group_removed: '*Groep verwijderd',
      offer_updated: '*Aanbieding bijgewerkt',
      dialogs: {
        create_group: {
          create_group: '*Groep aanmaken',
          group_created: '*Groep aangemaakt',
          name: '*Naam',
          save_group: '*Groep opslaan',
        },
        update_products: {
          update_products: '*Producten bijwerken',
          delivery_code_updated: '*Leveringscode bijgewerkt',
          label_type_rules_updated: '*Labeltype-regels bijgewerkt',
          groups: '*Groepen',
          stores: '*Winkels',
          change: '*Wijzig',
          all: '*Alle',
        },
      },
    },
    order_summary: {
      search_here: 'Zoek op bestelnummer',
      order_summary: 'Order samenvatting',
      order: 'Bestelling',
      shipment: 'Verzending',
      recurring_orders: 'Eerder besteld',
      returns: 'Retours',
      note: 'Notitie',
      resolution_cases: '*CaseHub',
      resends: '*Nasturen',
      emails: '*E-mails',
    },
    resolutions: {
      resolution_dossiers: 'CaseHub',
      resends: 'Nasturen',
      email_sent: 'E-mail verzonden',
      no_items: 'Geen items',
      show_all: 'Toon alles',
      response: 'Reactie',
      is_openend: 'Is geopend',
      is_not_openend: 'Is niet geopend',
      customer_question: 'Klantvraag',
      indicator: {
        has_case: 'Heeft case',
        has_unread_email: 'Heeft ongelezen e-mail',
      },
      email_variables: {
        email_variables: 'E-mail variabelen',
        customer_name: 'Klantnaam',
        store_name: 'Winkelnaam',
        transporter_name: 'Naam vervoerder',
      },
      dialogs: {
        handle_unanswered_customer_question_email: {
          dialog_title: 'Let op!',
          message:
            'Je wilt een bol.com klantvraag sluiten zonder dat je geantwoord hebt. Hiermee sluit je niet de klantvraag in bol.com en blijft deze open staan.',
          button_label: 'Bevestig',
        },
        create_email_template: {
          dialog_title: 'Stuur e-mail',
          email_template_created: 'E-mail sjabloon gemaakt',
          create_email_template: 'Maak e-mail sjabloon',
          name: 'Naam',
          subject: 'Onderwerp',
          body: 'Inhoud',
          attachments: 'Bijlagen',
        },
        update_email_template: {
          dialog_title: 'Update e-mail template',
          email_template_updated: 'E-mail sjabloon bijgewerkt',
          update_email_template: 'Update e-mail sjabloon',
          name: 'Naam',
          subject: 'Onderwerp',
          body: 'Inhoud',
          attachments: 'Bijlagen',
        },
        send_email: {
          transporter_unknown: 'Vervoerder onbekend',
          dialog_title: 'Stuur e-mail',
          send_email: 'Verzend e-mail',
          forward_email: 'Doorsturen',
          email_template: 'E-mail sjabloon',
          phase: 'Fase',
          email_sent: 'E-mail verzonden',
          subject: 'Onderwerp',
          recipient: 'Ontvanger',
          body: 'Inhoud',
          start_with_case: 'Begin met case',
          attachments: 'Bijlagen',
          archive_email_template: 'Archiveer e-mail sjabloon',
          email_template_archived: 'E-mail sjabloon gearchiveerd',
        },
        send_email_thread_reply: {
          dialog_title: 'Verzend e-mail',
          send_reply: 'Reply',
          reply_sent: 'E-mail verzonden',
          body: 'Inhoud',
        },
        create_resend: {
          dialog_title: 'Nasturen',
          note: 'Notitie',
          create_resend: 'Nasturen',
          resend_created: 'Nasturen aangemaakt',
        },
      },
      actions: {
        handle_case: 'Afgehandeld',
        case_handled: 'Afgehandeld',
        handle_resend: 'Nagestuurd',
        resend_handled: 'Nasturen',
        case_awaiting_response: 'Afwachting reactie',
        case_set_to_awaiting_response: 'Afwachting reactie',
      },
      email_type: {
        return_item: 'Retour item',
        order_item: 'Bestel item',
        shipment: 'Verzending',
      },
      resolution_dossier_status: {
        open: 'Open',
        handled: 'Afgehandeld',
        awaiting_response: 'Afwachting reactie',
      },
      resolution_case_status: {
        open: 'Open',
        handled: 'Afgehandeld',
        awaiting_response: 'Afwachting reactie',
      },
      resend_status: {
        open: 'Open',
        handled: 'Afgehandeld',
      },
      recipient_type: {
        recipient_type: 'Ontvangers-type',
        customer: 'Klant',
        bol: 'Partnerservice',
        transporter: 'Vervoerder',
        seller: 'Verkoper',
      },
      phase: {
        order: 'Bestelling',
        return: 'Retour',
        shipment: 'Verzending',
        other: 'Anders',
      },
      resolution_case_table: {
        subject: 'Onderwerp',
      },
      resolution_dossier_table: {
        phase: 'Fase',
        recipient_type: 'Ontvangers-type',
        note: 'Notitie',
        order_info: 'Bestellingsinformatie',
        subject: 'Onderwerp',
        created_date_time: 'Aangemaakt op',
        new_reply: 'Reactie',
        is_old: 'Oud',
        awaiting_reply_response: 'Afwachting',
      },
      resend_table: {
        order_items: 'Bestel items',
        order_number: 'Bestelnummer',
        shipment_details: 'Verzenddetails',
        note: 'Notitie',
      },
      email_messages_table: {
        sender: 'Afzender',
        recipient: 'Ontvanger',
        body: 'Inhoud',
        sent_date_time: 'Verzonden op',
      },
    },
    pick_orders: {
      status: 'Status',
      approve_pick_orders: 'Verzenden',
      pick_orders_rejected: '*Verwerpen',
      reject_pick_orders: 'Teruggezet',
      pick_orders_approved: 'Verzenden bezig',
    },
    orders: {
      order_item_status: {
        cancelled: '*Geannuleerd',
      },
      delivery_code: {
        delivery_code: '*Leveringscode',
        a24uurs_23: '*24-uurs levering om 23:00',
        a24uurs_22: '*24-uurs levering om 22:00',
        a24uurs_21: '*24-uurs levering om 21:00',
        a24uurs_20: '*24-uurs levering om 20:00',
        a24uurs_19: '*24-uurs levering om 19:00',
        a24uurs_18: '*24-uurs levering om 18:00',
        a24uurs_17: '*24-uurs levering om 17:00',
        a24uurs_16: '*24-uurs levering om 16:00',
        a24uurs_15: '*24-uurs levering om 15:00',
        a24uurs_14: '*24-uurs levering om 14:00',
        a24uurs_13: '*24-uurs levering om 13:00',
        a24uurs_12: '*24-uurs levering om 12:00',
        a1_2d: '*1-2 dagen',
        a2_3d: '*2-3 dagen',
        a3_5d: '*3-5 dagen',
        a4_8d: '*4-8 dagen',
        a1_8d: '*8+ dagen',
        mijnleverbelofte: '*Mijn leverbelofte',
        vvb: '*VVB levering',
      },
      no_items: 'Geen bestellingen gevonden',
      no_shipped_orders_this_days: '*Geen verzonden bestellingen op deze dagen',
      shipped_orders: '*Verzonden bestellingen',
      open_product_page: '*Open productpagina',
      bulk_mail: 'BulkMail',
      select_items: 'Selecteer orders',
      abort_bulk_mail: 'Annuleer BulkMail',
      dialogs: {
        update_internal_reference: {
          dialog_title: 'Update interne referentie',
          internal_reference: 'Interne referentie',
          add_internal_reference: 'Voeg interne referentie toe',
          update_internal_reference: 'Update interne referentie',
          internal_reference_updated: 'Interne referentie bijgewerkt',
          internal_reference_max_length_warning: 'Waarschuwing maximale lengte interne referentie',
        },
        update_order_note: {
          dialog_title: 'Update notitie',
          update_note: 'Update notitie',
          note_updated: 'Notitie bijgewerkt',
          note: 'Notitie',
        },
        update_offer: {
          update_offers: '*Aanbiedingen bijwerken',
          price: '*Prijs',
          stock: '*Voorraad',
          stock_amount: '*Aantal voorraad',
          corrected_stock: '*Gecorrigeerde voorraad',
          delivery_code: '*Leveringscode',
          listing: '*Vermelding',
          offer_updated: '*Aanbieding bijgewerkt',
          one_euro_up: '*Een euro omhoog',
          one_euro_down: '*Een euro omlaag',
        },
      },
      tutorials: {
        without_retailer_message:
          'Welkom!\n\nOm aan de slag te kunnen dien je eerst je winkel te koppelen met onze app. ',
        without_retailer_button: 'Koppel nu je winkel',
        with_retailer_message:
          'Al je orders zijn ingeladen! Selecteer hoe je het wil verzenden en druk op verwerken.\n' +
          '\n' +
          'Tip: Meerdere artikelen in 1 bestelling staan altijd bovenaan. \n' +
          '\n' +
          'Voor meer uitleg kijk op de FAQ',
        with_retailer_button: 'Naar de FAQ',
        internal_reference_info:
          "BlueEngine zet standaard jouw 'Interne referentie' onderaan het verzendlabel. Wanneer dit veld leeg is in jouw account, dan wordt jouw producttitel gebruikt. Let wel op, deze is vaak erg lang. Wij adviseren daarom het veld wel in te vullen wat voor jouw herkenbaar is.\n" +
          '\n' +
          'Zoals bijvoorbeeld -> (LEGO-KASTEEL-BLAUW)',
      },
      orders: 'Bestellingen',
      bol_order_id: 'Bestelnummer',
      order_items: 'Bestel items',
      recipient: 'Klant',
      store: 'Winkel',
      cancel: 'Annuleren',
      cancellation_request: 'Annuleerverzoek',
      cancellation_requests_approved: 'Annuleerverzoeken verwerkt',
      toasts: {
        label_type_changed: 'Verzendtype gewijzigd',
      },
      buttons: {
        start_vvb_pick_session: 'Verwerk VVB labels',
        start_non_vvb_pick_session: 'Kies vervoerder',
        set_to_mailbox: 'Selectie naar brievenbus',
        set_to_mailbox_success: 'Selectie naar brievenbus gezet',
        set_to_parcel: 'Selectie naar pakket',
        set_to_parcel_success: 'Selectie naar pakket gezet',
        copy: {
          copy_customer_name: 'Naam',
          customer_name_copied: 'Naam gekopieerd',
          copy_zip_code: 'PC',
          zip_code_copied: 'Post code gekopieerd',
          copy_internal_reference: 'IR',
          internal_reference_copied: 'Interne referentie gekopieerd',
          copy_city: 'Stad',
          city_copied: 'Stad gekopieerd',
          copy_street_name: 'Straat',
          street_name_copied: 'Straatnaam gekopieerd',
          copy_house_number: 'Hs#',
          house_number_copied: 'Huisnummer gekopieerd',
          copy_house_number_extension: 'Tvg',
          house_number_extension_copied: 'Huisnummer toevoeging gekopieerd',
        },
      },
      status: {
        open: 'Openstaand',
        open_later: 'Wachtrij',
        pick_session_pending: 'Pakketlabels bezig',
        shipped: 'Verzonden',
        cancelled: 'Annuleringen',
        failed: 'Foutief',
        can_not_process: 'Niet verwerkbaar',
      },
      selection_set_to_mailbox: 'Selectie naar brievenbus',
      cancel_order_item: 'Annuleer',
      order_item_cancelled: 'Bestel item geannuleerd',
      order_item_cancellation_explanation:
        "Annuleringen werken het zelfde als in je verkoopaccount. \n\n Selecteer alleen 'annuleerverzoek door klant' aan als dat werkelijk het geval is. Anders kan dit gevolgen hebben voor je verkoopaccount.",
      cancellation_reason: {
        cancellation_reason: 'Annuleringsreden',
        out_of_stock: 'Niet op voorraad',
        requested_by_customer: 'Annuleringsverzoek door klant',
        bad_condition: 'Slechte staat',
        higher_shipcost: 'Hogere verzendkosten',
        incorrect_price: 'Incorrecte prijs',
        not_avail_in_time: 'Niet tijdig beschikbaar',
        no_bol_guarantee: 'Geen bol garantie',
        ordered_twice: 'Tweemaal besteld',
        retain_item: 'Artikel behouden',
        tech_issue: 'Technisch probleem',
        unfindable_item: 'Onvindbaar artikel',
        other: 'Anders',
      },
      can_not_process_external_processed_order:
        'Bestelling is deels buiten BlueEngine verwerkt en kan hier niet verwerkt worden.',
      can_not_process_order_with_conflicting_handover_date_times:
        'Conflicterende laatste overdrachtsdatums; kan niet door BlueEngine verwerkt worden.',
      can_not_process_order_loading_error: 'Probleem met laden van bestelling.',
      is_vvb_non_vvb_combo: 'De bestelling is deels niet-vvb en kan hier niet verwerkt worden.',
      shipment_date_time: 'Verzenddatum en tijd',
      latest_handover_date: {
        latest_handover_date: 'Stel hier een afwijkende verzenddatum in',
        explanation:
          'Let op! Wanneer een VVB order te lang open staat, wordt deze door Bol.com geannuleerd.',
        let_bol_decide: 'Zet terug naar originele verzenddag',
        latest_handover_date_updated: 'Laatste overdrachtsdatum geüpdatet',
      },
      force_do_not_ignore_latest_handover_date_explanation:
        'Door de drukte op de servers van Bol.com is het tijdelijk niet mogelijk om VVB labels aan te maken voor dagen anders dan actuele verzenddag.',
      order_placed_date_tooltip: 'Besteldatum',
      repeat_customer_tooltip: 'Klant heeft al eerder besteld',
      repeat_customer: 'Eerder besteld',
      latest_handover_date_tooltip: 'Verzenddatum',
      expected_delivery_date_tooltip: 'Verwachte leverdatum',
      approve_customer_cancellations: 'Bevestig annuleringsverzoeken',
      errors: {
        request_already_in_progress:
          'Je orders worden ingeladen, refresh de pagina over enkele minuten.',
        unknown_error: 'Onbekende foutmelding, probeert het over enkele minuten nogmaals.',
      },
    },
    retailers: {
      no_items: 'Geen koppelingen gevonden',
      tutorials: {
        without_retailer_message:
          'Koppel hier je Bol.com winkel.\n' +
          '\n' +
          '1. Klik op de onderstaande knop en druk op ‘Aanmaken’ voor Client Credentials. Type BlueEngine, en druk op enter.\n' +
          '\n' +
          '2. Vervolgens kopieer je beide codes hieronder. Voila! Je bent klaar om te beginnen.',
        without_retailer_button: 'Naar Bol.com credentials',
        with_retailer_dialog_title: 'Succesvol gekoppeld!',
        with_retailer_dialog_message: 'Je hebt nu je winkel succesvol gekoppeld!',
        with_retailer_dialog_button: 'Haal nu je VVB bestellingen op',
      },
      retailer: 'Winkelier',
      retailers: 'Winkels',
      name: 'Winkelnaam',
      created_date: 'Aanmaakdatum',
      retailer_blocked_explanation:
        'Je verkoopnummer bestaat al of is geblokkeerd. Neemt contact op met support.',
      status: {
        status: 'Status',
        blocked: 'Geblokkeerd',
        active: 'Actief',
      },
      add_retailer: {
        add_retailer: 'Voeg koppeling toe',
        add_retailer_explanation: 'Kopieer hier de code',
        add_retailer_success: 'Koppeling toegevoegd',
        client_id: 'Client ID',
        secret: 'Secret',
        retailer_added: 'Koppeling toegevoegd',
      },
    },
    reports: {
      reports: 'Rapportages',
      quarter: '*Kwartaal',
      download: '*Downloaden',
    },
    pick_sessions: {
      is_shipped_automatically: "Zet bestellingen ook direct op 'verzonden'",
      tutorials: {
        message:
          'Hier staan al je aangemaakte pakketlabels. Je kunt hier de paklijst downloaden en de pakketlabels met jouw referentie erop. Mocht er een error zijn met één van je bestellingen, dan wordt dat hier aangegeven. \n' +
          '\n' +
          'De pakketlabels worden 14 dagen bewaard.',
      },
      no_items: 'Geen labels gevonden',
      confirm_start_pick_session: '*Bevestig start pick sessie',
      non_vvb_be_nl_mix_warning: '*Non-VVB BE NL mix waarschuwing',
      pick_sessions: 'Pakketlabels',
      progress: '*Voortgang',
      failures: '*Fouten',
      phase: {
        phase: '*Fase',
        failed: '*Mislukt',
        shipping: '*Verzending bezig',
        shipped: '*Verzonden',
        approval_pending: '*Goedkeuring in afwachting',
        creating_labels: '*Labels aanmaken',
        shipping_pending: '*Verzending in afwachting',
      },
      pick_order_status: {
        failed: '*Mislukt',
        rejected: '*Afgewezen',
        shipped: '*Verzonden',
      },
      buttons: {
        download_pick_list: 'Download picklijst',
        download_enriched_labels: 'Download labels',
        download_enriched_labels_disabled: 'Labels niet beschikbaars',
        download_clean_labels: 'Download labels zonder order info',
        download_clean_labels_disabled: 'Download labels zonder order info niet beschikbaar',
        download_pick_session_csv: '*Download pick sessie CSV',
        download_pick_session_csv_disabled: '*Pick sessie CSV niet beschikbaar',
      },
      label_generation: {
        label_generation: '*Label generatie',
        manual: '*Handmatig',
        buy_from_bol: '*Koop bij Bol',
        vvb: '*VVB',
      },
      pick_session_started: '*Pick sessie gestart',
      transporter_codes: {
        transporter_code: '*Vervoerder code',
        none: '*Geen geselecteerd',
        tnt: '*PostNL',
        tnt_brief: '*PostNL Brievenbus',
        rjp: '*RJP',
        dhlforyou: '*DHL voor jou',
        budbee: '*Budbee',
        dhl_sd: '*DHL',
        tnt_extra: '*PostNL Extra',
        tnt_express: '*PostNL Express',
        dyl: '*DYL',
        dpd_nl: '*DPD Nederland',
        dpd_be: '*DPD België',
        bpost_be: '*BPost België',
        bpost_brief: '*BPost Brief',
        gls: '*GLS',
        fedex_nl: '*FedEx Nederland',
        fedex_be: '*FedEx België',
        dhl_de: '*DHL Duitsland',
        dhl_global_mail: '*DHL Global Mail',
        other: '*Anders',
        tsn: '*TSN',
        transmission: '*Transmissie',
        parcel_nl: '*Pakket Nederland',
        packs: '*Packs',
        courier: '*Koerier',
        trunkrs: '*Trunkrs',
        pes: '*PES',
        cycloon: '*Cycloon',
        ups: '*UPS',
        unknown: '*Onbekend',
      },
    },
    ltr: {
      tutorials: {
        message:
          'Hier geef je aan hoe jouw product standaard verzonden moet worden. Als een product onbekend of nieuw is in ons systeem, dan nemen wij automatisch de instellingen vanuit jouw verkoopaccount over. \n' +
          '\n' +
          'Wordt jouw product bij meerdere aantallen als pakket verzonden? Dan kun je dat aangeven in de rechterkolom.',
      },
      no_items: 'Geen verzendregels gevonden',
      label_type_changed: 'Verzendregel aangepast',
      set_all_to_mailbox: 'Alles naar brievenbus',
      all_set_to_mailbox: 'Alles naar brievenbus gezet',
      set_all_to_parcel: 'Alles naar pakket',
      all_set_to_parcel: 'Alles naar pakket gezet',
      set_all_to_bol_recommended: 'Alles naar verkoopaccount',
      all_set_to_bol_recommended: 'Alles naar verkoopaccount gezet',
    },
    alerts: {
      alerts: 'Meldingen',
      order: '*Bestelling',
      trigger: '*Trigger',
      actions: 'Acties',
      dismiss: 'Negeer',
      dismissed: 'Melding genegeerd',
      execute: '*Uitvoeren',
      execute_all: '*Alles uitvoeren',
      event_rule_info: {
        info: 'Info',
        recoverability_unclear_return_item_registered:
          '*Onduidelijke herstelbaarheid retour item geregistreerd',
        order_is_placed_double:
          'Even navragen aan de klant of de bestelling inderdaad dubbel geplaatst is. <br/> <br/> Wellicht heeft de klant per ongeluk dubbel besteld, wat betekent dat er een retour gaat komen. <br/> <br/> Maar misschien klopt het wel, en kun je deze gezamenlijk versturen. Bespaart een verzendlabel.',
        pickup_point_will_return_to_sender:
          'Soms heeft de klant niet door dat het bij een parcelshop ligt, of is het simpelweg vergeten. Door te klant te attenderen dat het daar ligt kan deze vaak nog tijdig worden afgehaald. Dit bespaart een retourtje en het weer opnieuw versturen',
        vvb_shipment_returned_to_sender_by_transporter:
          '1. Vaak wordt een pakket onterecht retour gestuurd door je vervoerder. In meeste gevallen kun je dan ook gecompenseerd worden voor de verzendkosten. Er staat een standaard aanvraag klaar.' +
          '<br/> <br/>' +
          '2. Informeer je klant dat het teruggestuurd is en dat je het opnieuw moet versturen. Zo weet de klant wat er gebeurd is en scheelt dit weer een klantvraag.' +
          '<br/> <br/>' +
          '3. Maak een nastuur-opdracht aan en verzend het pakket opnieuw.' +
          '<br/> <br/>' +
          '4. Het pakket moet nog wel retour komen, deze monitoren we voor je. Wanneer je de retourscanner app gebruikt, wordt dit direct aangegeven bij het scannen.',
        non_vvb_shipment_returned_to_sender_by_transporter:
          '1. Vaak worden pakketten onterecht retour gestuurd, hiervoor kun je compensatie aanvragen. Bij VVB-labels gaat dat via Bol.com. Deze compensatie vind je terug in het vergoedingsoverzicht.' +
          '<br/> <br/>' +
          '2. Informeer je klant dat het teruggestuurd is. Het snelst is wanneer de klant even opnieuw besteld.' +
          '<br/> <br/>' +
          '3. Het pakket moet nog wel retour komen, deze monitoren we voor je. Wanneer je de retourscanner app gebruikt, wordt dit direct aangegeven bij het scannen.',
        order_overdue:
          'Heb je je pakketten te laat verstuurd? Door alvast een mail te sturen informeer je jouw klant en weet hij of zij dat het later wordt geleverd. Dit scheelt weer een klantvraag.',
        shipment_returned_to_sender_by_pickup_point:
          '1. Informeer en vraag je klant of het nogmaals verstuurd moet worden. Vaak wil de klant het alsnog ontvangen.' +
          '<br/> <br/>' +
          '2. Je kunt een vergoedingsaanvraag indienen bij bol.com voor het opnieuw moeten versturen van het pakket.' +
          '<br/> <br/>' +
          '3. Het pakket moet nog wel retour komen, deze monitoren we voor je. Wanneer je de retourscanner app gebruikt, wordt dit direct aangegeven bij het scannen.',
        return_item_rejected:
          'Wanneer je een retour afkeurd omdat bijvoorbeeld een verkeerd retourartikel is, dient het artikel teruggestuurt te worden naar de klant. Hiervoor maak je een terugstuur-opdracht aan.' +
          '<br/> <br/>' +
          'Voor de retourkosten en terugstuur kosten kun je vergoeding aanvragen bij Bol.com.' +
          '<br/> <br/>' +
          'Eingelijk hoort hier nog een infromeer mail in.',
        vvb_shipment_overdue:
          'Wanneer VVB-pakket blijft hangen, dan dient er onderzoek gestart te worden via Bol.com. Mocht het pakket kwijt zijn, dat je graag 75% LIM wilt.' +
          '<br/> <br/>' +
          'Informeer je klant dat de bestelling vaststaat en dat er een onderzoek gedaan wordt. Scheelt weer een klantvraag.',
        non_vvb_shipment_overdue:
          'Wanneer niet-VVB-pakket blijft hangen, dan dient er onderzoek gestart te worden via je vervoerder.' +
          '<br/> <br/>' +
          'Informeer je klant dat de bestelling vaststaat en dat er een onderzoek gedaan wordt. Scheelt weer een klantvraag.' +
          '<br/> <br/>' +
          'Uitslag, is weer informeer klant, of ga 75% lim, nasturu',
        recoverable_return_item_registered:
          'Wanneer de klant een retourreden geeft welke op te lossen is, is het handig de klant te vragen hoe hij dit opgelost zou zien.',
        return_item_expired:
          'Het kan zijn dat de retour nog niet verwerkt is in je magazijn, of dat de klant de retour uberhaupt nog niet heeft geretourneerd. Er zijn meerdere opties mogelijk.' +
          '<br/> <br/>' +
          'Of retourpakket staat vast bij vervoerder. Start onderzoek en/of vraag RLIM aan' +
          '<br/> <br/>' +
          'Of mail klant dat pakket niet tijdig teruggestuurd en dat het pakket helaas nu te laat is voor terugsturen' +
          '<br/> <br/>' +
          'Of zelf onderzoeken in eigen magazijn of pakket wel ontvangen is.',
      },
      event_rule_action_type: {
        send_customer_email: 'Informeer klant via e-mail',
        send_request_refund_email: 'Start vergoedingsaanvraag',
        create_resend: 'Nasturen',
        monitor_return_to_sender: 'Monitor retour afzender',
      },
    },
    auto_mail: {
      name: 'Naam',
      conditions: 'Voorwaarden',
      event_rules: 'AutoMail',
      product: 'Product',
      distribution_party: 'Distributiepartij',
      country_code: 'Landcode',
      recipient: 'Ontvanger',
      form: {
        create_event_rule: 'Maak e-mail regel',
        update_event_rule: 'Update e-mail regel',
        event_rule_created: 'E-mail regel aangemaakt',
        event_rule_updated: 'E-mail regel bijgewerkt',
        execute_in_days: 'Uitvoeren in dagen',
        execute_at_time: 'Uitvoeren op tijd',
        trigger_required: 'Trigger vereist',
        one_country_code_required: 'Een landcode vereist',
        one_distribution_party_required: 'Een distributiepartij vereist',
        email_template_required: 'E-mail sjabloon vereist',
        hours_required: 'Uren vereist',
        days_required: 'Dagen vereist',
        time_required: 'Tijd vereist',
        delay_days: 'Vertraging dagen',
        delay_hours: 'Vertraging uren',
        delay_time: 'Vertraging tijd',
      },
      disabled: 'Uitgeschakeld',
      enabled: 'Ingeschakeld',
      update_email_template: 'Update e-mail sjabloon',
      update_event_rule: 'Update e-mail regel',
      triggers: {
        trigger: 'Trigger',
        new_order: 'Nieuwe bestelling',
        order_is_placed_double: 'Bestelling is dubbel geplaatst',
        customer_is_recurring: 'Klant is terugkerend',
        shipment_track_and_trace_uploaded: 'Verzending track en trace geüpload',
        shipment_send: 'Verzending verzonden',
        shipment_delivered: 'Verzending geleverd',
        shipment_at_pickup_point: 'Verzending bij afhaalpunt',
        shipment_returned_to_sender: 'Verzending geretourneerd naar afzender',
        return_registered: 'Aangemeld',
        recoverable_return_item_registered: 'Oplosbaar',
        recoverability_unclear_return_item_registered: 'Onbekend',
        not_recoverable_return_item_registered: 'Onoplosbaar',
        return_handled: 'Retour afgehandeld',
        expected_delivery_date_reached: 'Verwachte leverdatum bereikt',

        shipment_returned_to_sender_by_transporter:
          'Verzending terug naar afzender door vervoerder',
        shipment_returned_to_sender_by_pickup_point:
          'Verzending terug naar afzender door afhaalpunt',
        pickup_point_will_return_to_sender: 'Afhaalpunt binnenkort terug naar afzender',
        shipment_overdue: 'Verzending te laat',
        return_item_rejected: 'Retour item afgekeurd',
        return_item_expired: 'Retour item verlopen',
        order_overdue: 'Bestelling te laat',
      },
      delay_type: {
        delay: 'Vertraging',
        none: 'Geen',
        hours: 'Uren',
        days: 'Dagen',
        time: 'Tijd',
      },
    },
    label_type: {
      label_type: 'Verzendtype',
      parcel: 'Pakket',
      bol_recommended: 'Bol aanbevolen',
      mailbox: 'Brievenbus',
    },
    settings: {
      settings: 'Instellingen',
      automatically_approve_cancel_requests: 'Automatisch annuleerverzoeken goedkeuren',
      automatically_approve_cancel_requests_tooltip:
        "Verwerk automatisch de klantannuleringen en zet ze in het tab 'Annuleringen'.",
      ignore_latest_handover_date: 'Negeren voorkeur verzenddatum door klant',
      ignore_latest_handover_date_tooltip:
        "Standaard worden jouw bestellingen gesorteerd per gewenste verzenddag, en gezet in de tab 'Wachtrij'.\n\n\nWil je alles per dag verzenden, zonder daar rekening mee te houden, zet dan deze functie aan.",
      save_settings: 'Instellingen opslaan',
      settings_saved: 'Instellingen opgeslagen',
    },
    pricing: {
      title: '*Titel',
      subtitle: '*Ondertitel',
      tier_1: {
        title: '*Niveau 1',
        feature_1: '*Functie 1',
        feature_2: '*Functie 2',
      },
      tier_2: {
        title: '*Niveau 2',
        feature_1: '*Functie 1',
        feature_2: '*Functie 2',
      },
      tier_3: {
        title: '*Niveau 3',
        feature_1: '*Functie 1',
        feature_2: '*Functie 2',
      },
      ex_vat: '*Exclusief BTW',
      monthly: '*Maandelijks',
      subscribe: '*Abonneren',
      payment_successful: '*Betaling geslaagd',
      payment_failed: '*Betaling mislukt',
      subscription_deactivated: '*Abonnement gedeactiveerd',
      trial_expired: '*Proefperiode verlopen',
    },
    shipments: {
      actions: {
        add_track_and_trace: {
          track_and_trace: 'Track en trace',
          track_and_trace_added: 'Track en trace toegevoegd',
        },
      },
      no_items: 'Geen zendingen gevonden.',
      shipments: 'TrackingView',
      no_shipments_on_this_day: 'Geen zendingen op deze dag',
      total_shipments_track_and_traceble: 'Totaal zendingen traceerbaar',
      total_shipments_non_track_and_traceble: 'Totaal zendingen niet traceerbaar',
      total_delivered: 'Bezorgd',
      total_at_pickup_point: 'Parcelshop',
      total_returned_to_sender: 'Retour afzender',
      total_in_transit: 'Onderweg',
      total_at_transporter: 'Sorteercentrum',
      total_inbound_collect: 'Totaal inkomende collect',
      total_pre_announced: 'Totaal vooraf aangekondigd',
      is_resent_after_return_tooltip: 'Opnieuw verstuurd',
      customer: 'Klant',
      internal_reference: 'Product',
      transporter: 'Vervoerder',
      expectedDeliveryDate: 'Beloofde leverdatum',
      retailer: 'Winkel',
      distribution_party: {
        bol: 'VVB',
        retailer: 'Non-VVB',
        mixed: 'VBB+Non-VVB',
      },
      status: {
        status: 'Status',
        waiting_for_events: 'Aangemeld.',
        pre_announced: 'Aangemeld',
        at_transporter: 'Sorteercentrum',
        in_transit: 'Onderweg',
        not_applicable: 'N.v.t.',
        inbound_collect: 'Inbound collect',
        at_pick_up_point: 'Bij Parcelshop',
        picked_up_at_pick_up_point: 'Opgehaald bij Parcelshop',
        delivered_at_customer: 'Bezorgd',
        delivered_at_neighbours: 'Bezorgd bij buren',
        returned_to_sender: 'Retour afzender',
        overdue: 'Te laat',
      },
      tabs: {
        all: 'Overzicht',
        at_pick_up_point: 'Bij Parcelshop',
        returned_to_sender: 'Retour afzender',
        overdue: 'Te laat',
      },
      copy_email: 'Email',
      email_copied: 'Email-adres gekopieerd',
      load_shipment_events: 'Update status',
      loading_shipment_events_started: 'Bezorging statussen worden opgehaald',
    },
    returns: {
      no_items: 'Geen items',
      is_recurring: 'Opnieuw retour aangemeld',
      is_new: 'Is nieuw',
      returns: 'Retouren',
      today: 'Vandaag',
      yesterday: 'Gisteren',
      day_before_yesterday: 'Eergisteren',
      is_outside_return_window: 'Retourtermijn overschreden',
      day_old: 'Dag',
      days_old: 'Dagen',
      other: 'Anders',
      product: 'Product',
      recipient: 'Ontvanger',
      send_email: 'Verzend e-mail',
      create_email_template: 'Maak template',
      processing_results: 'Verwerkingsresultaten',
      return_reason: 'Reden retour',
      rma_id: 'RMA ID',
      track_and_trace: 'Track en trace',
      registration_date_time: 'Registratiedatum en tijd',
      days_until_expiry: 'Dagen tot vervaldatum',
      quantity: 'Hoeveelheid',
      total_unhandled: 'Openstaande retouren',
      status: {
        new: 'Gescand',
        handled: 'Afgehandeld',
        overview: 'Overzicht',
        awaiting_exchange: 'Ruilen',
        on_hold: 'On-hold',
        awaiting_repair: 'In reparatie',
      },
      recoverability: {
        recoverable: 'Oplosbaar',
        not_recoverable: 'Bekend',
        unclear: 'Onbekend',
        recoverability_updated: 'Herstelbaarheid bijgewerkt',
      },
      processing_result: {
        accepted: 'Geaccepteerd',
        rejected: 'Afgewezen',
        cancelled: 'Geannuleerd',
      },
      handling_result: {
        return_received: 'Retour ontvangen',
        exchange_product: 'Product omruilen',
        return_does_not_meet_conditions: 'Retour voldoet niet aan voorwaarden',
        repair_product: 'Product repareren',
        customer_keeps_product_paid: 'Klant houdt product (betaald)',
        still_approved: 'Nog steeds goedgekeurd',
        customer_keeps_product_free_of_charge: 'Klant houdt product (gratis)',
        return_item_lost: 'Retour item verloren',
        expired: 'Verlopen',
        excessive_return: 'Excessieve retour',
        still_received: 'Nog steeds ontvangen',
        cancelled_by_customer: 'Geannuleerd door klant',
        failed_to_create_shipping_label: 'Niet gelukt om verzendlabel aan te maken',
        under_investigation: 'Onderzoek loopt',
        failed_to_collect_by_transporter: 'Niet gelukt om op te halen door vervoerder',
      },
      detailed_reason: {
        product_damaged: 'Product beschadigd',
        product_defective: 'Product defect',
        product_incomplete: 'Product incompleet',
        quality_not_as_expected: 'Kwaliteit valt tegen',
        delivery_too_late: 'Levering te laat',
        product_information_unclear: 'Productinformatie onduidelijk',
        product_image_unclear: 'Productafbeelding onduidelijk',
        too_big: 'Te groot',
        too_small: 'Te klein',
        received_product_as_gift: 'Product ontvangen als cadeau',
        changed_mind: 'Heeft zich bedacht',
        unknown: 'Onbekend',
      },
      actions: {
        accept: 'Accepteren',
        return_item_accepted: 'Retour item geaccepteerd',
        still_accept: 'Nog steeds accepteren',
        processing_result_still_accepted: 'Verwerkingsresultaat nog steeds geaccepteerd',
        reject: 'Afwijzen',
        return_item_rejected: 'Retour item afgewezen',
        set_to_awaiting_exchange: 'Ruilen',
        set_to_awaiting_arrival: 'In reparatie',
        set_to_on_hold: 'On-hold',
        set_to_awaiting_repair: 'In reparatie',
        set_to_new: 'Naar overzicht',
        send_email: 'Verzend e-mail',
        update_seller_comments: 'Opslaan retouropmerking',
        seller_comments_updated: 'Retouropmerking opgeslagen',
      },
      dialogs: {
        handle_return_item: {
          dialog_title: 'Afhandelen',
          approve_return: 'Retour goedkeuren',
          reject_return: 'Retour afwijzen',
          handle_return_explanation: 'Retour uitleg behandelen',
        },
        create_return: {
          dialog_title: 'Retour aanmaken',
          retailer: 'Winkelier',
          bol_order_id: 'Bol bestelnummer',
          bol_order_item_id: 'Bol order item bestelnummer',
          quantity_returned: 'Hoeveelheid geretourneerd',
          handling_result: 'Resultaat afhandeling',
          return_created: 'Retour aangemaakt',
        },
        send_email: {
          send_email: 'Verzend e-mail',
        },
      },
    },
  },
  customer_order: {
    send_to_pickup_point_thank_you_message:
      'Bedankt voor het doorgeven!\n' +
      '\n' +
      'Je pakket wordt via DHL bij het dichtstbijzijnde servicepunt gebracht. Je ontvangt een mail van DHL met de trackingcode vandaag of morgen. \n' +
      '\n' +
      'Het kan zijn dat de DHL mail in je spambox of ongewenste mail terecht komt. Houd deze daarom in de gaten!',
    send_to_pickup_point_already_shipped_message:
      '"Je pakket is al onderweg en wordt bij jou thuis bezorgd!"',
  },
  pd: {
    performance: {
      tabs: {
        offer_performance: 'Offer performances',
        product_performance: 'Product performances',
        ad_search_term_performance: 'Advertentie zoekterm prestaties',
      },
      performances: 'Prestaties',
      no_items: 'Geen items',
      performance_note_form: {
        note: 'Notitie',
        update_note: 'Update notitie',
        note_updated: 'Notitie bijgewerkt',
      },
      offer_performances_table: {
        offer_performances: 'Offer performances',
        date: 'Datum',
      },
      competitor_product_performances_table: {
        position: 'Positie',
        no_data: 'Geen gegevens',
      },
      timeline_table: {
        seasonals: {
          new_years_day: 'Nieuwjaarsdag',
          valentines_day: 'Valentijnsdag',
          international_womens_day: 'Internationale vrouwendag',
          saint_patricks_day: 'Sint Patricksdag',
          international_day_of_happiness: 'Internationale dag van geluk',
          april_fools_day: '1 april',
          ascension_day: 'Hemelvaartsdag',
          mothers_day: 'Moederdag',
          liberation_day: 'Bevrijdingsdag',
          fathers_day: 'Vaderdag',
          summer_break: 'Zomerpauze',
          teachers_day: 'Leraren dag',
          halloween: 'Halloween',
          christmas_day: 'Kerstmis',
          new_years_eve: 'Oudjaarsavond',
          saint_nicholas_day: 'Sinterklaas',
          good_friday: 'Goede vrijdag',
          world_animal_day: 'Werelddierendag',
          eid_al_fitr: 'Eid al-Fitr',
          feast_of_sacrifice: 'Offerfeest',
          carnaval: 'Carnaval',
          first_pentecost_day: 'Eerste Pinksterdag',
          second_pentecost_day: 'Tweede Pinksterdag',
          black_friday: 'Black Friday',
          cyber_monday: 'Cyber Monday',
          chinese_new_year: 'Chinees Nieuwjaar',
          kings_day: 'Koningsdag',
          secretary_day: 'Secretaressedag',
        },
      },
      offer_performances_totals: {
        total_profit: 'Totale winst',
        total_ad_costs: 'Totale advertentiekosten',
        total_visits: 'Totale bezoeken',
        total_articles_sold: 'Totale artikelen verkocht',
        conversion_rate: 'Conversieratio',
        total_revenue: 'Totale omzet',
        total_purchase_value: 'Totale inkoopwaarde',
      },
      volume_discount_indicator: {
        volume_discount: 'Volume korting',
        yes: 'Ja',
        no: 'Nee',
        price_unknown: 'Prijs onbekend',
      },
      organic_non_organic_table: {
        organic_vs_non_organic: 'Biologisch versus niet-biologisch',
        option: {
          all: 'Alle',
          organic: 'Biologisch',
          non_organic: 'Niet-biologisch',
        },
        conversion_rate: 'Conversieratio',
        orders_conversion_rate: 'Bestellingen conversieratio',
        articles: 'Artikelen',
        orders: 'Bestellingen',
        visits: 'Bezoeken',
      },
      offer_performance_search_metrics_table: {
        search_metrics: 'Zoekstatistieken',
        impressions: 'Impressies',
        ctr: 'CTR',
        weighted_rank: 'Gewogen rang',
      },
      offer_performance_metrics_table: {
        metrics: 'Statistieken',
        daily_profit: 'Dagelijkse winst',
        yearly_profit: 'Jaarlijkse winst',
        ROIDE: 'ROI DE',
      },
      ad_performances_table: {
        ad_performances: 'Advertentieprestaties',
        impressions: 'Impressies',
        clicks: 'Klikken',
        ctr: 'CTR',
        conversions: 'Conversies',
        conversion_rate: 'Conversieratio',
        sales: 'Verkopen',
        acos: 'ACOS',
        cpc: 'CPC',
        spent: 'Uitgegeven',
      },
      product_rank_search_term_category_table: {
        type: 'Type',
        label: 'Label',
        average_rank: 'Gemiddelde rang',
        total_impressions: 'Totale impressies',
        total_volume: 'Totaal volume',
        is_relevant: 'Is relevant',
        is_relevant_updated: 'Is relevant bijgewerkt',
      },
      ad_group_search_term_performance_search_terms_table: {
        search_term: 'Zoekterm',
        total_impressions: 'Totale impressies',
        total_clicks: 'Totale klikken',
        impressions_share: 'Impressie aandeel',
        click_share: 'Klik aandeel',
        total_conversions: 'Totale conversies',
        total_sales: 'Totale verkopen',
        total_spent: 'Totaal uitgegeven',
        average_cpc: 'Gemiddelde CPC',
        average_acos: 'Gemiddelde ACOS',
        average_ctr: 'Gemiddelde CTR',
        average_conversion_rate: 'Gemiddelde conversieratio',
        average_winning_bid_average: 'Gemiddelde winnende bod gemiddelde',
        total_search_volume: 'Totale zoekvolume',
      },
      search_term_volume_chart: {
        title: 'Titel',
        volume: 'Volume',
      },
      assortment_impressions_chart: {
        title: 'Titel',
        impressions: 'Impressies',
      },
      assortment_visits_chart: {
        title: 'Titel',
        visits: 'Bezoeken',
      },
      ad_group_search_term_performance_chart: {
        ad_group_search_term_performance: 'Advertentiegroep zoekterm prestaties',
        impressions: 'Impressies',
        clicks: 'Klikken',
        ctr: 'CTR',
        conversions: 'Conversies',
        conversion_rate: 'Conversieratio',
        sales: 'Verkopen',
        spent: 'Uitgegeven',
        cpc: 'CPC',
        acos: 'ACOS',
        roas: 'ROAS',
        average_winning_bid: 'Gemiddelde winnende bod',
        search_volume: 'Zoekvolume',
        click_share_percentage: 'Klik aandeel percentage',
        impression_share_percentage: 'Impressie aandeel percentage',
      },
      ad_group_performance_chart: {
        ad_group_performance: 'Advertentiegroep prestaties',
        impressions: 'Impressies',
        clicks: 'Klikken',
        conversions: 'Conversies',
        sales: 'Verkopen',
        spent: 'Uitgegeven',
        impression_share: 'Impressie aandeel',
        click_share: 'Klik aandeel',
      },
      product_ranks_chart: {
        product_ranks: 'Product rangen',
        loaded_rank: 'Ingeladen rang',
        rank: 'Rang',
        impressions: 'Impressies',
      },
      create_offer_shipping_costs_form: {
        date: 'Datum',
        shipping_costs: 'Verzendkosten',
        create_shipping_costs: 'Maak verzendkosten',
        shipping_costs_created: 'Verzendkosten aangemaakt',
      },
      update_offer_shipping_costs_form: {
        date: 'Datum',
        shipping_costs: 'Verzendkosten',
        update_shipping_costs: 'Update verzendkosten',
        shipping_costs_updated: 'Verzendkosten bijgewerkt',
      },
      create_product_purchase_costs_form: {
        date: 'Datum',
        purchase_costs: 'Inkoopkosten',
        create_purchase_costs: 'Maak inkoopkosten',
        purchase_costs_created: 'Inkoopkosten aangemaakt',
      },
      update_product_purchase_costs_form: {
        date: 'Datum',
        purchase_costs: 'Inkoopkosten',
        update_purchase_costs: 'Update inkoopkosten',
        purchase_costs_updated: 'Inkoopkosten bijgewerkt',
      },
      create_product_purchase_volume_form: {
        date: 'Datum',
        purchase_volume: 'Inkoopvolume',
        create_purchase_volume: 'Maak inkoopvolume',
        purchase_volume_created: 'Inkoopvolume aangemaakt',
      },
      update_product_purchase_volume_form: {
        date: 'Datum',
        purchase_volume: 'Inkoopvolume',
        update_purchase_volume: 'Update inkoopvolume',
        purchase_volume_updated: 'Inkoopvolume bijgewerkt',
      },
      create_product_reorder_interval_form: {
        date: 'Datum',
        reorder_interval: 'Bestelinterval',
        create_reorder_interval: 'Maak bestelinterval',
        reorder_interval_created: 'Bestelinterval aangemaakt',
      },
      update_product_reorder_interval_form: {
        date: 'Datum',
        reorder_interval: 'Bestelinterval',
        update_reorder_interval: 'Update bestelinterval',
        reorder_interval_updated: 'Bestelinterval bijgewerkt',
      },
      create_offer_performance_overwrite_form: {
        date: 'Datum',
        visits: 'Bezoeken',
        create_offer_performance_overwrite: 'Maak aanbodprestatie overschrijven',
        offer_performance_overwrite_created: 'Aanbodprestatie overschrijven aangemaakt',
      },
      update_offer_performance_overwrite_form: {
        date: 'Datum',
        visits: 'Bezoeken',
        update_offer_performance_overwrite: 'Update aanbodprestatie overschrijven',
        offer_performance_overwrite_updated: 'Aanbodprestatie overschrijven bijgewerkt',
      },
      create_manual_seasonal_event_form: {
        create_manual_seasonal_event: 'Maak handmatig seizoensevenement',
        manual_seasonal_event_created: 'Handmatig seizoensevenement aangemaakt',
        date: 'Datum',
        name: 'Naam',
      },
      create_manual_performance_event_form: {
        create_manual_performance_event: 'Maak handmatig prestatie-evenement',
        manual_performance_event_created: 'Handmatig prestatie-evenement aangemaakt',
        date: 'Datum',
        note: 'Notitie',
      },
    },
    products: {
      products: 'Producten',
      no_items: 'Geen items',
      product: 'Product',
    },
    offer_dropdown_select: {
      label: 'Label',
    },
    store_dropdown_select: {
      label: 'Label',
    },
    category_dropdown_select: {
      label: 'Label',
    },
    campaign_dropdown_select: {
      label: 'Label',
    },
    rolling_average_days_dropdown_select: {
      label: 'Label',
    },
    deviation_chart_type_dropdown_select: {
      label: 'Label',
    },
    ad_group_dropdown_select: {
      label: 'Label',
    },
    search_term_dropdown_select: {
      label: 'Label',
    },
    latest_days_dropdown_select: {
      label: 'Label',
      latest_14_days: 'Laatste 14 dagen',
      latest_30_days: 'Laatste 30 dagen',
      latest_60_days: 'Laatste 60 dagen',
      latest_90_days: 'Laatste 90 dagen',
      latest_150_days: 'Laatste 150 dagen',
    },
    contested_category_dropdown_select: {
      label: 'Label',
      contested_category_activated: 'Betwiste categorie geactiveerd',
    },
  },
  errors: {
    401: {
      title: '401',
      subtitle: 'Je bent niet ingelogd, log in om verder te gaan.',
    },
    404: {
      title: '404',
      subtitle: 'De pagina is niet beschikbaar, probeer het later opnieuw.',
    },
    500: {
      title: '500',
      subtitle: 'Er gaat iets fout aan onze kant, probeer het later opnieuw.',
    },
    back_to_home: 'Terug naar inloggen',
  },
}) as TranslationTokens;
