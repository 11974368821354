import { DialogId } from '../../../store/dialogs/DialogId';
import { useIsDialogOpen } from '../../../store/dialogs/useIsDialogOpen';
import { useTranslation } from 'react-i18next';
import { ApplicationDialog } from '../../../components/ApplicationDialog';

export const InfoDialog = () => {
  const dialogId = DialogId.INFO;

  const { isOpen, data } = useIsDialogOpen(dialogId);
  // const closeDialog = useCloseDialog(dialogId);
  const { t } = useTranslation();

  return (
    <>
      {isOpen && data && (
        <ApplicationDialog
          dialogId={dialogId}
          title={t(data.title)}
        >
          <div dangerouslySetInnerHTML={{ __html: t(data.message) as string }}></div>
        </ApplicationDialog>
      )}
    </>
  );
};

export interface InfoDialogData {
  title: string;
  message: string;
}
