import { EndpointQueryKey } from './EndpointQueryKey';
import { EventRuleType } from '../../domain/automator/eventRules/EventRuleType';

export const EventRulesQueryKey = (type?: EventRuleType) => {
  if (!type) {
    return [EndpointQueryKey.EVENT_RULES];
  }

  return [EndpointQueryKey.EVENT_RULES, { type }];
};
