import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useState } from 'react';
import { ResolutionDossierStatus } from '../../../domain/automator/resolutionDossiers/ResolutionDossierStatus';
import { useFetchResolutionDossiers } from '../../../api/automator/resolutions/useFetchResolutionDossiers';
import ResolutionDossierTabs from './components/ResolutionDossierTabs';
import { ResolutionDossierListTable } from './components/ResolutionDossierListTable';

const ResolutionDossiersPage = () => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState(ResolutionDossierStatus.OPEN);
  const { data: resolutionDossiers, isLoading } = useFetchResolutionDossiers(currentTab, page);

  const onUpdateCurrentTab = (tab: ResolutionDossierStatus) => {
    switch (tab) {
      case ResolutionDossierStatus.OPEN:
      case ResolutionDossierStatus.AWAITING_RESPONSE: {
        setPage(undefined);
        break;
      }
      case ResolutionDossierStatus.HANDLED: {
        setPage(1);
      }
    }
    setCurrentTab(tab);
  };

  return (
    <AccountPage title={tokens.automator.resolutions.resolution_dossiers}>
      <ResolutionDossierTabs
        report={resolutionDossiers ? resolutionDossiers.report : null}
        currentTab={currentTab}
        setCurrentTab={onUpdateCurrentTab}
      />

      <ResolutionDossierListTable
        resolutionDossiers={resolutionDossiers && resolutionDossiers.resolutionDossiers}
        isLoading={isLoading}
        pagination={(resolutionDossiers && resolutionDossiers.pagination) ?? null}
        onPageChange={setPage}
      />
    </AccountPage>
  );
};

export default ResolutionDossiersPage;
