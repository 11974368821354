import { tokens } from '../../../locales/translationTokens';
import AccountPage from '../../../components/AccountPage';
import { useFetchAlerts } from '../../../api/automator/alerts/useFetchAlerts';
import { AlertsListTable } from './components/AlertsListTable';
import { AlertStatus } from '../../../domain/automator/alerts/AlertStatus';

const AlertsPage = () => {
  const { data: alerts, isLoading } = useFetchAlerts(AlertStatus.AWAITING);

  return (
    <AccountPage title={tokens.automator.alerts.alerts}>
      <AlertsListTable
        alerts={alerts?.alerts}
        isLoading={isLoading}
      />
    </AccountPage>
  );
};

export default AlertsPage;
